import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../index";
import { sizes } from "../../theme";

const StyledButtonLink = styled(Button.withComponent(Link))`
    display: inline-block;
    text-decoration: inherit;
    line-height: ${props => sizes[props.size]}em;
`;

const ButtonLink = props => {
    console.warn(
        "ButtonLinks are depreciated. Please use a Button with element prop instead."
    );
    return <StyledButtonLink {...props} />;
};

ButtonLink.displayName = "ButtonLink";

export default ButtonLink;
