import React, { useEffect } from "react";
import { isBrowser } from "./index";
export const useClickOutside = (ref, callback) => {
    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            return callback();
        }
    };

    useEffect(() => {
        if (isBrowser()) {
            document.addEventListener("click", handleClickOutside);
        }
        return () => {
            if (isBrowser()) {
                document.removeEventListener(
                    "click",
                    handleClickOutside,
                    false
                );
            }
        };
    });
};

export default useClickOutside;
