import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import InputError from "../styledElements/inputError";
import FormLabel from "../styledElements/label";
import StyledInput from "../styledElements/styledInput";

const FormikInput = ({
    type,
    name,
    label,
    disabled,
    labelWidth,
    labelAlign,
    ...rest
}) => {
    // const handleChange = event => {
    //     onChange({
    //         name: name,
    //         value: event.target.value
    //     });
    // };

    return (
        <React.Fragment>
            <Field name={name} {...rest}>
                {({ field, form: { touched, errors } }) => {
                    const error = !!touched[field.name] && errors[field.name];
                    return (
                        <React.Fragment>
                            {label && (
                                <FormLabel
                                    align={labelAlign}
                                    width={labelWidth}
                                    name={name}
                                    {...rest}
                                >
                                    {label}
                                </FormLabel>
                            )}
                            <StyledInput
                                {...rest}
                                type={type}
                                name={name}
                                id={name}
                                labelWidth={labelWidth}
                                onChange={field.onChange}
                                error={error}
                                disabled={disabled}
                            />
                        </React.Fragment>
                    );
                }}
            </Field>
            <ErrorMessage name={name}>
                {msg => {
                    //console.log("msg", msg);
                    return <InputError error={msg} />;
                }}
            </ErrorMessage>
        </React.Fragment>
    );
};

FormikInput.displayName = "FormikInput";

FormikInput.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    height: PropTypes.number,
    labelWidth: PropTypes.number,
    labelAlign: PropTypes.string,
    height: PropTypes.number
};

FormikInput.defaultProps = {
    size: "default",
    type: "text",
    disabled: false,
    height: 38,
    labelWidth: "100%"
};

export default FormikInput;
