import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

import FormLabel from "../styledElements/label";
import InputError from "../styledElements/inputError";
import StyledCheckbox from "../styledElements/styledCheckbox";
import { Collapse } from "react-collapse";

const Container = styled.div`
    margin-bottom: ${props => props.theme.spacing.margin - 0.2}em;
`;

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: ${({ depth }) => depth * 1}rem;
`;

const CheckboxAndLabel = styled(StyledCheckbox)`
    flex-grow: 1;
`;

const ExpandArrow = styled(FontAwesomeIcon)``;

class CheckboxTree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: props.value ? props.value : [],
            expandedOptions: []
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                values: this.props.value || []
            });
        }
    }

    handleChange(event, value) {
        const { onChange } = this.props;
        let newValues = this.state.values.slice();
        if (event.target.checked) {
            newValues.push(value);
        } else {
            newValues.splice(newValues.indexOf(value), 1);
        }
        this.setState({
            values: newValues
        });
        return onChange({ name: this.props.name, value: newValues }, event);
    }
    generateCheckboxes = (
        name,
        options,
        depth = 0
        // checkboxes = []
    ) => {
        // console.log("options", options);
        const { values, expandedOptions } = this.state;
        return options.map(option => {
            const isChecked = values.indexOf(option.value) > -1;
            const hasChildren =
                Array.isArray(option.children) && option.children.length > 0;
            const expandedOptionIndex = expandedOptions.indexOf(option.value);
            const isShowingChildren = expandedOptionIndex > -1;
            // console.log("checkboxes", checkboxes, option);
            return (
                <React.Fragment key={option.value}>
                    <CheckboxWrapper depth={depth}>
                        <CheckboxAndLabel
                            key={option.value}
                            name={name}
                            onChange={event =>
                                this.handleChange(event, option.value)
                            }
                            checked={isChecked}
                            text={option.text}
                            marginBottom={this.props.marginBottom}
                        />
                        {hasChildren && (
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                rotation={isShowingChildren ? 270 : 90}
                                onClick={() => {
                                    if (isShowingChildren) {
                                        this.setState({
                                            expandedOptions: [
                                                ...expandedOptions.slice(
                                                    0,
                                                    expandedOptionIndex
                                                ),
                                                ...expandedOptions.slice(
                                                    expandedOptionIndex + 1
                                                )
                                            ]
                                        });
                                    } else {
                                        this.setState({
                                            expandedOptions: [
                                                ...expandedOptions,
                                                option.value
                                            ]
                                        });
                                    }
                                }}
                            />
                        )}
                    </CheckboxWrapper>
                    {hasChildren && (
                        <Collapse isOpened={isShowingChildren}>
                            {this.generateCheckboxes(
                                name,
                                option.children,
                                depth + 1
                                // values,
                                // expandedOptions
                                // checkboxes
                            )}
                        </Collapse>
                    )}
                </React.Fragment>
            );
        });

        // return checkboxes;
    };

    render() {
        const {
            options,
            onBlur,
            error,
            inlineRadioButtons,
            name,
            ...rest
        } = this.props;

        return (
            <Container>
                {this.props.label && (
                    <FormLabel {...rest}>{this.props.label}</FormLabel>
                )}
                {/* {checkboxes} */}
                {this.generateCheckboxes(name, options, 0)}

                <InputError error={error} />
            </Container>
        );
    }
}
CheckboxTree.displayName = "CheckboxTree";

CheckboxTree.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired
        })
    ).isRequired,
    error: PropTypes.string,
    inlineRadioButtons: PropTypes.bool
};

export default CheckboxTree;
