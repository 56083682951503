import React from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { foregroundColor } from "@cortexglobal/cortex-utilities";
import {
    darken,
    lighten,
    hexToRgbaString
} from "@cortexglobal/cortex-utilities";
// import { RubberBand } from "animate-css-styled-components";

const background = props => {
    if (
        props.theme.button.types[props.type] &&
        props.theme.button.types[props.type].backgroundColor
    ) {
        return props.theme.button.types[props.type].backgroundColor;
    }
    return (
        props.theme.colors[props.type] ||
        props.theme.colors[props.theme.button.default]
    );
};
const foreground = props => {
    if (
        props.theme.button.types[props.type] &&
        props.theme.button.types[props.type].foregroundColor
    ) {
        return props.theme.button.types[props.type].foregroundColor;
    }
    return "#000000";
};

const color = props =>
    foregroundColor(
        background(props),
        0.75,
        props.theme.lightColor,
        props.theme.darkColor
    );

const isHollow = props => Boolean(props.hollow);

const isExpanded = props => Boolean(props.expanded);

const outline = props => `inset 0 0 0 ${props.theme.button.borderWidth}px
${background(props)}`;

// let Button = styled(({ element, children, hollow, expanded, ...props }) => {
//     // console.log(props);
//     return React.createElement(element, props, children);
// }).attrs({ role: "button" })`
let Button = styled.button.attrs({ role: "button" })`
    border: ${props =>
        props.theme.button.types[props.type] &&
        props.theme.button.types[props.type].border
            ? props.theme.button.types[props.type].border
            : "none"};

    cursor: pointer;
    color: ${props => foreground(props)};
    text-align: ${props =>
        props.theme.button.types[props.type] &&
        props.theme.button.types[props.type].textAlign
            ? props.theme.button.types[props.type].textAlign
            : props.theme.button.textAlign};
    font-family: inherit;
    min-height: ${props => props.height}px;
    // line-height: ${props => props.height / 1.5}px;
    font-size: ${props => props.theme.button.fontSize}px;
    // font-weight: ${props => props.theme.button.fontWeight};
    text-transform: ${props =>
        props.theme.button.types[props.type] &&
        props.theme.button.types[props.type].textTransform
            ? props.theme.button.types[props.type].textTransform
            : props.theme.button.textTransform};
    border-radius: ${props => props.theme.button.borderRadius}px;
    transition: background-color 0.25s ease;
    box-shadow: ${props =>
        props.theme.button.types[props.type] &&
        props.theme.button.types[props.type].boxShadow
            ? props.theme.button.types[props.type].boxShadow
            : outline(props)};
    padding: 0
    ${props =>
        props.theme.button.types[props.type] &&
        props.theme.button.types[props.type].padding
            ? props.theme.button.types[props.type].padding
            : props.theme.spacing.padding}em;
        
    display: inline-block;
    text-decoration: ${props =>
        props.theme.button.types[props.type] &&
        props.theme.button.types[props.type].textDecoration
            ? props.theme.button.types[props.type].textDecoration
            : "none"};
    background-color: ${props => {
        if (isHollow(props)) {
            return "transparent";
        }

        const backgroundAlpha =
            props.theme.button.types[props.type] &&
            !isNaN(props.theme.button.types[props.type].backgroundAlpha)
                ? props.theme.button.types[props.type].backgroundAlpha
                : 1;
        return hexToRgbaString(background(props), backgroundAlpha);
    }};

    //  disabled button styles
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed;
        `};

    // active style
    ${props =>
        !isHollow(props) &&
        !props.disabled &&
        (!props.theme.button.types[props.type] ||
            props.theme.button.types[props.type].backgroundAlpha !== 0) &&
        css`
            &:hover {
                background-color: ${props => darken(background(props), 0.8)};
                box-shadow: none;
            }
        `};
    ${props =>
        isHollow(props) &&
        !props.disabled &&
        css`
            &:hover {
                color: ${props => lighten(background(props), 1.2)};
            }
        `};

    // expanded
    ${props =>
        isExpanded(props) &&
        css`
            width: 100%;
        `};

    // focus
    &:focus {
        outline: none;
        box-shadow: ${props => outline(props)},
            0px 0px 0px 3px ${props => hexToRgbaString(background(props), 0.2)};
    }
`;

// let Button = props => {
//     //BC FIX - Handling the removal of the 'element' from rendering, using the styled-components
//     //'as' prop instead
//     if (props.element) {
//         props.as = props.element;
//     }
//     return <StyledButton {...props} />;
// };
// Button = withTheme(Button);

Button.displayName = "Button";

Button.propTypes = {
    /** Optional: you can choose an element for the button. Defaults to button.*/
    element: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element,
        PropTypes.object
    ]),
    expanded: PropTypes.bool,
    disabled: PropTypes.bool,
    hollow: PropTypes.bool,
    margin: PropTypes.number,
    height: PropTypes.number
};

Button.defaultProps = {
    element: "button",
    expanded: false,
    disabled: false,
    hollow: false,
    height: 36,
    type: "default"
};

export default Button;
