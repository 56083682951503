import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import FormLabel from "../styledElements/label";
import InputError from "../styledElements/inputError";
import StyledCheckbox from "../styledElements/styledCheckbox";

const Container = styled.div`
    margin-bottom: ${props => props.theme.spacing.margin - 0.2}em;
`;

class MultiCheckbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: props.value ? props.value : []
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                values: this.props.value || []
            });
        }
    }

    handleChange(event, value) {
        const { onChange } = this.props;
        let newValues = this.state.values.slice();
        if (event.target.checked) {
            newValues.push(value);
        } else {
            newValues.splice(newValues.indexOf(value), 1);
        }
        this.setState({
            values: newValues
        });
        return onChange({ name: this.props.name, value: newValues }, event);
    }

    render() {
        const {
            options,
            onBlur,
            error,
            inlineRadioButtons,
            name,
            disabled,
            ...rest
        } = this.props;
        const { values } = this.state;

        const checkboxes = options.map(option => {
            const isChecked = values.indexOf(option.value) > -1;
            return (
                <StyledCheckbox
                    key={option.value}
                    name={name}
                    onChange={event => this.handleChange(event, option.value)}
                    checked={isChecked}
                    text={option.text}
                    disabled={disabled}
                    inlineRadioButtons={inlineRadioButtons}
                    marginBottom={this.props.marginBottom}
                />
            );
        });

        return (
            <Container>
                {this.props.label && (
                    <FormLabel {...rest}>{this.props.label}</FormLabel>
                )}
                {checkboxes}

                <InputError error={error} />
            </Container>
        );
    }
}
MultiCheckbox.displayName = "MultiCheckbox";

MultiCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired
        })
    ).isRequired,
    error: PropTypes.string,
    inlineRadioButtons: PropTypes.bool,
    disabled: PropTypes.bool
};

export default MultiCheckbox;
