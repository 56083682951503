import React from "react";
import PropTypes from "prop-types";
import LoadingIcon from "react-loading";
import styled, { css } from "styled-components";
import { colors } from "../../theme";
const LoadingOuter = styled.div`
    position: relative;
    padding: ${props => props.padding};
    width: ${props => props.width};
    height: ${props => props.height};
    ${props =>
        props.align == "center" &&
        css`
            //margin: auto;
        `} ${props =>
        props.align == "right" &&
        css`
            float: right;
        `};
`;

const LoadingInner = styled.div`
    margin: auto;
    width: 100%;
    height: 100%;
    max-width: 100px;
    max-height: 100px;
`;
const Loading = props => {
    return (
        <LoadingOuter>
            <LoadingInner>
                <LoadingIcon
                    type={props.type}
                    color={props.color}
                    width={"100%"}
                    height={"100%"}
                />
            </LoadingInner>
        </LoadingOuter>
    );
};
Loading.displayName = "Loading";

Loading.propTypes = {
    /** A css color (using rgba will allow transparency) */
    color: PropTypes.string,
    /** One of the types provided by react-loading, e.g. currently ["blank",
        "balls",
        "bars",
        "bubbles",
        "cubes",
        "cylon",
        "spin",
        "spinningBubbles",
        "spokes"] */
    type: PropTypes.oneOf([
        "blank",
        "balls",
        "bars",
        "bubbles",
        "cubes",
        "cylon",
        "spin",
        "spinningBubbles",
        "spokes"
    ]),
    /** A css width */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** A css height */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** left, right or center */
    align: PropTypes.string,
    padding: PropTypes.string
};

Loading.defaultProps = {
    color: colors.mediumGray,
    type: "spin",
    width: "100%",
    height: "100%",
    align: "left",
    padding: "0"
};
export default Loading;
