import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import CircularProgressBar from "react-circular-progressbar";

const ProgressContainer = styled.div`
    width: ${props => props.containerWidth};
    max-width: ${props => {
        return props.containerMaxWidth;
    }};
    margin: ${props => (props.center ? "auto" : 0)};
`;

const CircularProgress = props => {
    return (
        <ProgressContainer {...props}>
            <style>
                {`
                    .CircularProgressbar {
                        width: 100%;
                    }
                    
                    .CircularProgressbar .CircularProgressbar-path {
                        stroke: ${props.theme.circularProgress.strokeColor};
                        stroke-linecap: ${props.theme.circularProgress.strokeLinecap};
                        stroke-width: ${props.theme.circularProgress.strokeWidth}px;
                        transition: stroke-dashoffset 0.5s ease 0s;
                    }
                    
                    .CircularProgressbar .CircularProgressbar-trail {
                        stroke: ${props.theme.circularProgress.trailColor};
                        stroke-width: ${props.theme.circularProgress.trailWidth}px;
                    }
                    
                    .CircularProgressbar .CircularProgressbar-text {
                        fill: ${props.theme.circularProgress.textColor};
                        font-size: 20px;
                        dominant-baseline: middle;
                        text-anchor: middle;
                    }
                    `}
            </style>
            <CircularProgressBar {...props} />
        </ProgressContainer>
    );
};
CircularProgress.propTypes = {
    containerWidth: PropTypes.string,
    containerMaxWidth: PropTypes.string,
    center: PropTypes.bool,
    /** Any valid CSS colour value */
    strokeColor: PropTypes.string,
    /** Any valid CSS colour value */
    textColor: PropTypes.string,
    /** Any valid CSS colour value */
    trailColor: PropTypes.string
};
CircularProgress.defaultProps = {
    containerWidth: "80%",
    containerMaxWidth: "10em",
    center: true,
    strokeColor: "#3e98c7",
    textColor: "#fff",
    trailColor: "rgba(214,214,214, 0.1)"
};

export default withTheme(CircularProgress);
