import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled, { css } from "styled-components";
import FormLabel from "../styledElements/label";
import InputError from "../styledElements/inputError";
import StyledDatePicker from "../styledElements/datePicker/StyledDatePicker";
import { getLocaleDateFormat } from "@cortexglobal/cortex-utilities";

const DatePickerContainer = styled.div`
    width: 100%;
    max-width: ${props => (props.expanded ? "100%" : "100%")};
`;
class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: props.value
                ? props.value
                : props.allowEmpty
                ? ""
                : moment().format()
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                selectedDate: moment.isMoment(this.props.value)
                    ? moment(this.props.value).format()
                    : this.props.value
            });
        }
    }

    handleChange = date => {
        const newDate = date === null ? "" : date.format();
        this.setState({
            selectedDate: newDate
        });
        return this.props.onChange({ name: this.props.name, value: newDate });
    };

    render = () => {
        const field = this.props;
        const { selectedDate } = this.state;
        return (
            <DatePickerContainer>
                {this.props.label && (
                    <FormLabel {...field}>{this.props.label}</FormLabel>
                )}
                {this.props.easyRead && <b>{this.props.easyRead}</b>}
                <StyledDatePicker
                    {...field}
                    value={selectedDate}
                    // selected={moment(selectedDate)}
                    onChange={this.handleChange}
                />

                <InputError error={field.error} />
            </DatePickerContainer>
        );
    };
}

DatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    initialValue: PropTypes.any,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    emptyOption: PropTypes.string,
    error: PropTypes.string,
    allowEmpty: PropTypes.bool
};
DatePicker.defaultProps = {
    error: "",
    expanded: true,
    dateFormat: getLocaleDateFormat(),
    allowEmpty: false
};
export default DatePicker;
