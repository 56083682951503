import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

/**
 * TODO - Investigate swapping react-paginate for this:
 * https://github.com/ChoTotOSS/react-paginating
 * It uses render props, so would allow for a nicer component architecture
 * It doesn't appear to natively handle margin pages (neighbouring pages)
 * but this tutorial explains an option:
 * https://scotch.io/tutorials/build-custom-pagination-with-react
 */
import ReactPaginate from "react-paginate";
import ResultSummary from "./ResultSummary";

const FlexWrapper = styled.div`
    flex: 1 1 0px;
`;
/**
 * Having to use a wrapper to allow us to add CSS without the .css file, which allows us to hook into the theme
 */
export const PaginationWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .pagination {
        display: inline-flex;
        flex: 1 1 0px;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;
        margin: ${({ theme, margin }) =>
            margin
                ? margin
                : theme.pagination.margin
                ? theme.pagination.margin
                : "inherit"};
        li {
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            border: ${({ theme }) => theme.pagination.border};
            background: ${({ theme }) => theme.pagination.background};
            transition: all 500ms;
            margin: 0 0.1em;

            a {
                display: flex;
                min-height: ${({ theme }) => theme.pagination.height};
                line-height: ${({ theme }) => theme.pagination.height};
                padding-left: 15px;
                padding-right: 15px;
                color: ${({ theme }) => theme.pagination.color};
            }

            &.next a,
            &.previous a {
                font-size: ${({ theme }) => theme.pagination.arrowSize};
                color: ${({ theme }) => theme.pagination.arrowColor};
            }
            &:hover {
                background: ${({ theme }) => theme.pagination.hoverBackground};

                a {
                    color: ${({ theme }) => theme.pagination.hoverColor};
                }
            }
            &.active {
                background: ${({ theme }) => theme.pagination.activeBackground};
                border: none;
                a {
                    color: ${({ theme }) => theme.pagination.activeColor};
                    font-weight: ${({ theme }) =>
                        theme.pagination.activeFontWeight};
                }
            }
        }
    }
`;

const Pagination = ({
    children,
    currentPage,
    from,
    to,
    total,
    pageCount,
    onPageChange,
    marginPagesDisplayed,
    pageRangeDisplayed,
    previousLabel,
    nextLabel,
    breakLabel,
    breakClassName,
    containerClassName,
    subContainerClassName,
    activeClassName,
    alwaysShow,
    showSummary,
    ...rest
}) => {
    // console.log("pageCount", pageCount);
    if (!alwaysShow && typeof pageCount !== "undefined" && pageCount <= 1) {
        return null;
    }
    return (
        <PaginationWrapper {...rest}>
            {/* The section below could be used for the per page selector */}
            {showSummary && <FlexWrapper>&nbsp;</FlexWrapper>}
            <ReactPaginate
                onPageChange={onPageChange}
                pageCount={pageCount}
                forcePage={currentPage}
                marginPagesDisplayed={marginPagesDisplayed}
                pageRangeDisplayed={pageRangeDisplayed}
                previousLabel={previousLabel}
                nextLabel={nextLabel}
                breakLabel={breakLabel}
                breakClassName={breakClassName}
                containerClassName={containerClassName}
                subContainerClassName={subContainerClassName}
                activeClassName={activeClassName}
                {...rest}
            />
            {showSummary && (
                <FlexWrapper>
                    <ResultSummary from={from} to={to} total={total} />
                </FlexWrapper>
            )}
        </PaginationWrapper>
    );
};

Pagination.displayName = "Pagination";

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    /* The current starting result */
    from: PropTypes.number,
    /* The current ending result */
    to: PropTypes.number,
    /* The overall result total */
    total: PropTypes.number,
    marginPagesDisplayed: PropTypes.number,
    pageRangeDisplayed: PropTypes.number,
    previousLabel: PropTypes.string,
    nextLabel: PropTypes.string,
    breakLabel: PropTypes.string,
    breakClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    subContainerClassName: PropTypes.string,
    activeClassName: PropTypes.string,
    alwaysShow: PropTypes.bool,
    showSummary: PropTypes.bool
};

Pagination.defaultProps = {
    previousLabel: <span>&lsaquo;</span>,
    nextLabel: <span>&rsaquo;</span>,
    breakLabel: "...",
    marginPagesDisplayed: 1,
    pageRangeDisplayed: 2,
    breakClassName: "break-me",
    containerClassName: "pagination",
    subContainerClassName: "pages pagination",
    activeClassName: "active",
    alwaysShow: false,
    showSummary: true
};

export default Pagination;
