import React, { Component } from "react";
import styled, { css } from "styled-components";
import Row from "./row";
import Column from "./column";
import Panel from "./panel";

const Wrapper = styled.section`
    padding-top: ${props =>
        props.padding != undefined
            ? props.padding
            : props.theme.spacing.padding}rem;
    padding-bottom: ${props =>
        props.padding != undefined
            ? props.padding
            : props.theme.spacing.padding}rem;
    margin-bottom: ${props =>
        props.margin != undefined
            ? props.margin
            : props.theme.spacing.margin}rem;
    background-color: ${({ theme }) => theme.pageTitle.background};
`;

const Title = styled.h1`
    margin: 0 auto 0 0;
    font-size: ${({ theme }) => theme.pageTitle.fontSize};
    font-family: ${({ theme }) => theme.pageTitle.fontFamily};
`;

const Flex = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
`;

const PageTitle = ({ title, children, ...rest }) => (
    <Wrapper {...rest}>
        <Row style={{ marginBottom: 0 }} {...rest}>
            <Column>
                <Flex>
                    <Title>{title}</Title>
                    {children}
                </Flex>
            </Column>
        </Row>
    </Wrapper>
);

export default PageTitle;
