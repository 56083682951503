import React from "react";
import ShowMoreText from "react-show-more-text";
import { trans, useIntl } from "@cortexglobal/rla-intl";
//For now we're just making use of the react-show-more-text directly
//This component is here toe make it easier to swap out for our own at a later date,
//and to allow for translated versions of the default messages
export const ShowMore = ({ more = "", less = "", children, ...rest }) => {
    // const intl = useIntl();
    return (
        <ShowMoreText
            more={more === "" ? trans("Show more") : more}
            less={less === "" ? trans("Show less") : less}
            // more={more === "" ? intl.formatMessage({ id: "Show more" }) : more}
            // less={less === "" ? intl.formatMessage({ id: "Show less" }) : less}
            {...rest}
        >
            {children}
        </ShowMoreText>
    );
};
export default ShowMore;
