import React from "react";
import styled from "styled-components";

export const ValidationError = styled.span`
    font-size: 0.7rem;
    display: block;
    color: ${props => props.theme.input.error.color};
`;

export default ValidationError;
