import React from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import {
    TextareaField,
    MultiCheckbox,
    CheckboxTree,
    Select,
    SelectField,
    RadioField,
    Range,
    InputField,
    Row,
    Column
} from "../../index";

export const renderStandReturnInput = (input, value, onUpdate) => {
    // console.log(input);
    switch (input.type) {
        case "Textarea":
            return (
                <TextareaField
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    onChange={onUpdate}
                />
            );
        case "MultiCheckBox":
            return (
                <MultiCheckbox
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    options={input.options}
                    onChange={onUpdate}
                    defaultColor="accent"
                    activeColor="primary"
                    marginBottom={0.5}
                />
            );
        case "CheckboxTree":
            return (
                <CheckboxTree
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    options={input.options}
                    onChange={onUpdate}
                    defaultColor="accent"
                    activeColor="primary"
                    marginBottom={0.5}
                />
            );
        case "MultiSelect":
            return (
                <Select
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    options={input.options}
                    onChange={onUpdate}
                    multi={true}
                />
            );
        case "Select":
            return (
                <SelectField
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    options={input.options}
                    onChange={onUpdate}
                />
            );
        case "RadioGroup":
            return (
                <RadioField
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    options={input.options}
                    onChange={onUpdate}
                />
            );
        case "Range":
            return (
                <Range
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    onChange={onUpdate}
                />
            );
        default:
            return (
                <InputField
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    onChange={onUpdate}
                />
            );
    }
};
