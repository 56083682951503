import React, { JSXElementConstructor } from "react";
import { FormatNumberOptions } from "react-intl";
import { FormattedNumberWithLoader } from "./FormattedNumberWithLoader";

export const FormattedCurrency = ({
    value,
    loaded = true,
    currency = "GBP",
    options
}: {
    value: number;
    loaded: boolean;
    currency: string;
    options?: FormatNumberOptions;
}) => {
    let amendedOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
        style: "currency",
        currency
    };

    return (
        <FormattedNumberWithLoader
            value={value}
            loaded={loaded}
            options={amendedOptions}
        />
    );
};
