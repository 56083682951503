import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ErrorContainer = styled.div`
    position: relative;
    padding: ${props => props.padding || "20px"};
    margin: ${props => props.margin || "20px 0"};
    text-align: ${props => props.textAlign || "center"};
    background: ${props => props.background || props.theme.colors.lightGray};
    font-size: ${props => props.fontSize || "0.9em"};
`;

const ErrorIcon = styled.div`
    display: inline-block;
    width: 15px;
    height: 15px;
    background: red;
    color: white;
    border-radius: 50%;
    vertical-align: text-top;
    margin-right: 8px;
    font-weight: bold;
`;

const Error = props => {
    return (
        <ErrorContainer>
            <ErrorIcon>!</ErrorIcon>
            {props.children}
        </ErrorContainer>
    );
};

Error.displayName = "Error";

Error.propTypes = {
    padding: PropTypes.string,
    margin: PropTypes.string,
    textAlign: PropTypes.string,
    background: PropTypes.string,
    fontSize: PropTypes.string
};

export default Error;
