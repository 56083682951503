import React from "react";
import PropTypes from "prop-types";
//import 'react-select/dist/react-select.css';
// import makeAnimated from "react-select/animated";

import ReactSelect, { Creatable, makeAnimated } from "react-select";

import { baseInputStyle } from "./baseInput";
import { withTheme } from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
const generateCustomStyles = props => {
    return {
        option: (provided, { isDisabled, isFocused, isSelected }) => ({
            ...provided
            // backgroundColor: isSelected
            //     ? props.theme.input.borderColor
            //     : isFocused
            //     ? props.theme.input.focusColor
            //     : null
        }),
        container: (provided, state) => ({
            ...provided,
            marginBottom: props.marginBottom ? props.marginBottom : "1.2rem",
            minHeight: props.height ? props.height : 30
        }),
        control: (provided, state) => ({
            ...provided,
            // height: props.height ? props.height : 30,
            minHeight: `${props.height ? props.height : 30}px`,
            backgroundColor: props.theme.input.background,
            border: `1px solid ${
                props.error
                    ? props.theme.input.error.borderColor
                    : props.theme.input.borderColor
            }`,
            borderRadius: `${props.theme.input.radius}`
            // alignItems: "flex-start"
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
        },
        multiValue: (provided, state) => ({
            ...provided
            // height: props.height ? props.height / 2 : 15
            // backgroundColor: isSelected
            //     ? props.theme.input.borderColor
            //     : isFocused
            //     ? props.theme.input.focusColor
            //     : null
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            padding: "0 8px"
            // backgroundColor: isSelected
            //     ? props.theme.input.borderColor
            //     : isFocused
            //     ? props.theme.input.focusColor
            //     : null
        }),
        valueContainer: (provided, state) => ({
            ...provided,

            padding: "0 8px"
            // height: props.height ? props.height / 2 : 15
            // backgroundColor: isSelected
            //     ? props.theme.input.borderColor
            //     : isFocused
            //     ? props.theme.input.focusColor
            //     : null
        })
    };
};
const animatedComponents = makeAnimated();

class StyledSelectAdvanced extends React.Component {
    shouldKeyDownEventCreateNewOption = ({ keyCode }) => {
        return [9, 13, this.props.tags ? 188 : ""].indexOf(keyCode) != -1; // Create new options only on tab and enter
    };

    onChange = selected => {
        const { onChange, allowSelectAll, allOption } = this.props;
        if (allowSelectAll) {
            if (
                selected !== null &&
                selected.length > 0 &&
                selected[selected.length - 1].value === allOption.value
            ) {
                let { options } = this.props;

                return onChange(
                    options.map(option => {
                        option.label = option.text;
                        return option;
                    })
                );
            }
        }

        return onChange(selected);
    };

    processValue = (options, value) => {
        return options
            ? options
                  .filter(option => {
                      if (Array.isArray(value)) {
                          return (
                              value.filter(value => {
                                  return option.value === value;
                              }).length >= 1
                          );
                      }
                      return option.value === value;
                  })
                  .map(option => {
                      option.label = option.text;
                      return option;
                  })
            : "";
    };

    render() {
        const {
            name,
            onChange,
            error,
            creatable,
            tags,
            value,
            multi,
            allowSelectAll,
            theme,
            hideSelectedOptions,
            ...rest
        } = this.props;
        // console.log(value, options);
        let { options } = this.props;
        const Component = creatable || tags ? Creatable : ReactSelect;

        const customStyles = generateCustomStyles(this.props);

        if (allowSelectAll) {
            options = [this.props.allOption, ...options];
        }
        return (
            <Component
                {...rest}
                isMulti={multi}
                styles={customStyles}
                hideSelectedOptions={
                    allowSelectAll ? false : hideSelectedOptions
                }
                components={animatedComponents}
                name={name}
                options={options.map(option => ({
                    value: option.value,
                    label: option.text
                }))}
                defaultValue={this.processValue(options, value)}
                value={this.processValue(options, value)}
                onChange={this.onChange.bind(this)}
                shouldKeyDownEventCreateNewOption={
                    this.shouldKeyDownEventCreateNewOption
                }
            />
        );
    }
}

StyledSelectAdvanced = withTheme(StyledSelectAdvanced);
StyledSelectAdvanced.displayName = "StyledSelectAdvanced";

StyledSelectAdvanced.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            text: PropTypes.string.isRequired
        })
    ),
    allOption: PropTypes.shape({
        value: PropTypes.any,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired
    }),
    labelProps: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired
    }),
    placeholder: PropTypes.string,
    multi: PropTypes.bool,
    creatable: PropTypes.bool,
    allowSelectAll: PropTypes.bool,
    tags: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    height: PropTypes.number
};

StyledSelectAdvanced.defaultProps = {
    allowSelectAll: false,
    allOption: {
        text: trans("Select All"),
        value: "*"
    },
    error: "",
    labelProps: {
        label: ""
    },
    height: 38
};

export default StyledSelectAdvanced;
