import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const RadioDot = styled.circle.attrs(props => ({
    cx: props.theme.checkbox.size / 2,
    cy: props.theme.checkbox.size / 2
}))`
    r: 0;
    fill: ${props => props.theme.checkbox.color};
    transition: r 0.25s ease;
`;

const RadioCircle = styled.circle.attrs(props => ({
    r: props.theme.checkbox.size / 2 - 1,
    cx: props.theme.checkbox.size / 2,
    cy: props.theme.checkbox.size / 2
}))`
    stroke-width: 1px;
    fill: ${props => props.theme.checkbox.background};
    stroke: ${props => props.theme.checkbox.stroke};
`;

const Radio = styled.svg.attrs(props => ({
    width: props.theme.checkbox.size,
    height: props.theme.checkbox.size,
    viewBox: `0 0 ${props.theme.checkbox.size} ${props.theme.checkbox.size}`
}))`
    display: inline-block;
    vertical-align: middle;
`;

const Input = styled.input`
    display: none;
    &:checked + ${Radio} ${RadioDot} {
        r: ${props => props.theme.checkbox.size / 4}px;
    }
`;

const Wrapper = styled.label`
    margin-bottom: 0.2rem;
    display: ${props => (props.inlineRadioButtons ? "inline-block" : "block")};
    padding-right: ${props => props.theme.spacing.margin}rem;
    cursor: pointer;
`;

const Text = styled.span`
    display: inline-block;
    margin-left: 0.4rem;
    vertical-align: middle;
`;

const StyledRadio = ({
    checked,
    name,
    value,
    onChange,
    text,
    readOnly,
    error,
    inlineRadioButtons,
    ...rest
}) => {
    // console.log(inlineRadioButtons);
    return (
        <Wrapper inlineRadioButtons={inlineRadioButtons}>
            <Input
                {...rest}
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <Radio>
                <RadioCircle />
                <RadioDot />
            </Radio>
            <Text>{" " + text}</Text>
        </Wrapper>
    );
};

StyledRadio.displayName = "StyledRadio";

StyledRadio.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    inlineRadioButtons: PropTypes.bool,
    input: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    error: PropTypes.string
};
StyledRadio.defaultProps = {
    size: "default",
    expanded: false,
    inlineLabel: true,
    inlineRadioButtons: false,
    type: "text",
    readOnly: false,
    error: ""
};
export default StyledRadio;
