import React from "react";
import styled from "styled-components";

const IEMinHeightFix = styled.div`
    display: flex;
    /* min-height: 100%; */
`;
const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
`;

const SiteWrapper = ({ children, ...rest }) => (
    <IEMinHeightFix>
        <PageWrapper {...rest}>{children}</PageWrapper>
    </IEMinHeightFix>
);

export default SiteWrapper;
