import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { get } from "lodash";

import GridList from "./GridList";
import Table from "./Table";

class TableList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: undefined
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    };
    renderAsList() {
        const tableElements = React.Children.toArray(this.props.children);

        const headers = get(
            tableElements[0],
            "props.children.props.children",
            []
        ).reduce((headerArray, header) => {
            return headerArray.concat(header.props.children);
        }, []);

        const itemArray = get(tableElements[1], "props.children", []);
        const items = !Array.isArray(itemArray)
            ? []
            : itemArray.reduce((newItems, item) => {
                  //   console.log(item);
                  if (!item.props || !Array.isArray(item.props.children)) {
                      return newItems;
                  }
                  return newItems.concat([
                      [
                          ...item.props.children.reduce((newItem, property) => {
                              return newItem.concat(property.props.children);
                          }, [])
                      ]
                  ]);
              }, []);

        return <GridList headers={headers} items={items} />;
    }
    render() {
        var { type, respondsAt, ...rest } = this.props;
        if (
            type === "list" ||
            (type === "responsive" && this.state.width < respondsAt)
        ) {
            return this.renderAsList();
        }
        return (
            <Table style={{ width: "100%" }} {...rest}>
                {this.props.children}
            </Table>
        );
    }
}

TableList.displayName = "TableList";

TableList.propTypes = {
    /** Controls the type of element rendered, one of "responsive", "list", "table" */
    type: PropTypes.oneOf(["responsive", "list", "table"]),

    /** The pixel value to swap to a list from a table */
    respondsAt: PropTypes.number,

    /** Whether to use zebra stripes on the table */
    zebraStripe: PropTypes.bool,

    /** Whether to show a hover effect for table rows */
    showHover: PropTypes.bool,

    /** The TableList requires a single thead and tbody as direct chilren */
    children: function(props, propName, componentName) {
        const tableElements = React.Children.toArray(props["children"]);
        if (!Array.isArray(tableElements) || tableElements.length < 2) {
            return new Error(
                "The TableList must contain a single thead and single tbody as direct children."
            );
        }
        if (tableElements[0].type !== "thead") {
            return new Error(
                "The TableList must contain a thead as the first element"
            );
        }
        if (tableElements[1].type !== "tbody") {
            return new Error(
                "The TableList must contain a tbody as the first element"
            );
        }
    }
};

TableList.defaultProps = {
    respondsAt: 600,
    type: "responsive",
    zebraStripe: true,
    showHover: false
};

export default TableList;
