import React from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { foregroundColor } from "@cortexglobal/cortex-utilities";
import {
    darken,
    lighten,
    hexToRgbaString
} from "@cortexglobal/cortex-utilities";
// import { RubberBand } from "animate-css-styled-components";

const background = props => {
    if (
        props.theme.button.types[props.type] &&
        props.theme.button.types[props.type].backgroundColor
    ) {
        return props.theme.button.types[props.type].backgroundColor;
    }
    return (
        props.theme.colors[props.type] ||
        props.theme.colors[props.theme.button.default]
    );
};

// let Button = styled(({ element, children, hollow, expanded, ...props }) => {
//     // console.log(props);
//     return React.createElement(element, props, children);
// }).attrs({ role: "button" })`
let PlainButton = styled.button.attrs({ role: "button" })`
    cursor: pointer;
    font-family: inherit;
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    font-size: ${props => props.theme.button.fontSize}px;
    text-transform: ${props => props.theme.button.textTransform};

    //  disabled button styles
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed;
        `};

    ${props =>
        !props.disabled &&
        css`
            &:hover,
            &:focus {
                color: ${props => lighten(background(props), 1.2)};
            }
        `};
`;

PlainButton.displayName = "PlainButton";

PlainButton.propTypes = {
    disabled: PropTypes.bool,
    margin: PropTypes.number,
    height: PropTypes.number
};

PlainButton.defaultProps = {
    disabled: false,
    height: 36
};

export default PlainButton;
