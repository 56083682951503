import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { useDropzone } from "react-dropzone";

// const SimpleDropzone = styled(Dropzone)`
//     background: ${props => dropzone(props).background};
//     margin-bottom: ${props => props.theme.dropzone.margin}em;
//     padding: ${props => props.theme.dropzone.padding}em;
//     border: ${props => props.theme.dropzone.border};
//     color: ${props => dropzone(props).color};
// `;

export const SimpleDropzone = ({ onDrop, children }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop
    });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {children}
            {/* {isDragActive ? (
                <p>Drop the files here ...</p>
            ) : (
                
            )} */}
        </div>
    );
};

export default SimpleDropzone;
