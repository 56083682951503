import PropTypes from "prop-types";
import styled from "styled-components";

const TabContainer = styled.div`
    ${props =>
        props.fullWidth &&
        `width: 100%;
        display: table;
        table-layout: fixed;
        border-collapse: collapse;
        border-bottom:2px solid ${props.theme.tabordion.borderColor};
        margin-bottom:0.6em;
    `};

    ${props =>
        props.lineTabs &&
        `width: 100%;
        border-bottom:2px solid ${props.theme.tabordion.borderColor};
        margin-bottom:1em;
    `};
`;

TabContainer.displayName = "TabContainer";

export default TabContainer;
