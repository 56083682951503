import React from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { Row, Column, renderStandReturnInput } from "../../index";

const FilterTitle = styled.h4`
    font-weight: bold;
`;

const WrapperRow = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const FilterColumn = styled.div``;
let StandardFilters = ({ filters, selectedFilters, onUpdate, onClear }) => {
    const filterCount = filters.length;
    return (
        <WrapperRow>
            {filters.map(filter => {
                return (
                    <FilterColumn
                        key={filter.name}
                        style={{ marginTop: "1rem" }}
                    >
                        <FilterTitle>{filter.label}</FilterTitle>
                        {renderStandReturnInput(
                            filter,
                            selectedFilters[filter.name],
                            onUpdate
                        )}
                    </FilterColumn>
                );
            })}
        </WrapperRow>
    );
};

StandardFilters = withTheme(StandardFilters);

StandardFilters.displayName = "StandardFilters";

StandardFilters.propTypes = {
    /** Filters: Need to be of a certain shape, but this needs to be worked out.*/
    filters: PropTypes.array.isRequired,
    // filters: PropTypes.object.isRequired,
    selectedFilters: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired
};

StandardFilters.defaultProps = {};

export default StandardFilters;
