import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Tab from "./tabs/tab";
import TabContainer from "./tabs/tabContainer";
import TabContent from "./tabs/tabContent";
import Accordion from "./accordion/accordion";
import AccordionHeader, { AccordionHeading } from "./accordion/accordionHeader";
import AccordionContent from "./accordion/accordionContent";
import Steps from "./steps/steps";
import Step from "./steps/step";

const StepsContainer = styled.div`
    text-align: center;
`;
const TabordionContainer = styled.div`
    background: ${props => props.theme.tabordion.background};
`;

class Tabordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: this.props.current,
            width: undefined,
            random: this.randomString()
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.updateDimensions();
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentDidUpdate(prevProps) {
        if (this.props.current !== prevProps.current) {
            this.setCurrentItem(this.props.current);
        }

        if (this.props.cb) this.props.cb();
    }

    updateDimensions() {
        this.setState({ width: window.innerWidth });
    }
    setCurrentItem(x, breakpoint) {
        // console.log("set current item", x, this.state.current);

        if (
            (this.props.type == "accordion" || breakpoint) &&
            x === this.state.current
        ) {
            this.setState({ current: undefined });
        } else {
            this.setState({ current: x });
        }
    }
    randomString() {
        return Math.random()
            .toString(36)
            .substring(7);
    }
    position() {
        return this.state.current;
    }
    length() {
        return React.Children.count(this.props.children);
    }
    prev() {
        this.setCurrentItem(this.state.current - 1);
    }
    next() {
        this.setCurrentItem(this.state.current + 1);
    }
    handleTabClick(child, x) {
        if (child.props && child.props.onTabClick) {
            child.props.onTabClick();
        }
        if (this.props.onTabClick) {
            this.props.onTabClick();
        }

        if (x) {
            x();
        }
    }
    content(children, unmounts, current) {
        // console.log("current", current);
        return unmounts
            ? children[current]
            : children.map((child, i) => {
                  return (
                      <div
                          key={i}
                          style={{
                              display: current == i ? "block" : "none",
                              //   display: "block",
                              height: current == i ? "100%" : "0"
                          }}
                      >
                          {child}
                      </div>
                  );
              });
    }
    render() {
        const {
            type,
            respondsAt,
            responsive,
            unmounts,
            isWizard,
            nextButtonText,
            prevButtonText,
            nextButtonDisabled,
            prevButtonDisabled,
            wizardColumnProps,
            wizardRowProps,
            wizardButtonProps,
            ...rest
        } = this.props;
        const children = React.Children.toArray(this.props.children);
        const breakpoint = Boolean(responsive && this.state.width < respondsAt);
        const content = this.content(children, unmounts, this.state.current);

        return (
            <TabordionContainer {...rest}>
                {type == "tabs" && !breakpoint && (
                    <TabContainer fullWidth>
                        {children.map((child, i) => {
                            return (
                                <Tab
                                    onClick={() => {
                                        this.handleTabClick(
                                            child,
                                            this.setCurrentItem(i, breakpoint)
                                        );
                                    }}
                                    showDivider={this.props.showDivider}
                                    current={this.state.current}
                                    key={i}
                                    i={i}
                                >
                                    {child.props.heading}
                                </Tab>
                            );
                        })}
                    </TabContainer>
                )}
                {type == "linetabs" && !breakpoint && (
                    <TabContainer lineTabs>
                        {children.map((child, i) => {
                            return (
                                <Tab
                                    onClick={() =>
                                        this.handleTabClick(
                                            child,
                                            this.setCurrentItem(i, breakpoint)
                                        )
                                    }
                                    showDivider={this.props.showDivider}
                                    current={this.state.current}
                                    key={i}
                                    i={i}
                                >
                                    {child.props.heading}
                                </Tab>
                            );
                        })}
                    </TabContainer>
                )}
                {type == "steps" && !breakpoint && (
                    <StepsContainer>
                        <Steps current={this.state.current}>
                            {children.map((child, i) => {
                                return (
                                    <Step
                                        label={child.props.heading}
                                        onClick={() =>
                                            this.handleTabClick(
                                                child,
                                                this.setCurrentItem(
                                                    i,
                                                    breakpoint
                                                )
                                            )
                                        }
                                        divider={this.props.divider}
                                        key={"step_" + i}
                                        i={i}
                                    >
                                        {child.props.icon}
                                    </Step>
                                );
                            })}
                        </Steps>
                    </StepsContainer>
                )}
                {type == "accordion" || breakpoint ? (
                    <React.Fragment>
                        <AccordionGroup
                            slice={[
                                0,
                                !isNaN(this.state.current)
                                    ? this.state.current + 1
                                    : children.length
                            ]}
                            breakpoint={breakpoint}
                            divider={this.props.divider}
                            current={this.state.current}
                            children={children}
                            handleTabClick={this.handleTabClick.bind(this)}
                            cb={this.setCurrentItem.bind(this)}
                        />
                        <AccordionContent isOpened={true}>
                            {content}
                        </AccordionContent>
                        {!isNaN(this.state.current) && (
                            <AccordionGroup
                                slice={[
                                    this.state.current + 1,
                                    children.length
                                ]}
                                breakpoint={breakpoint}
                                divider={this.props.divider}
                                current={this.state.current}
                                children={children}
                                handleTabClick={this.handleTabClick.bind(this)}
                                cb={this.setCurrentItem.bind(this)}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    content
                )}
            </TabordionContainer>
        );
    }
}

class AccordionGroup extends React.Component {
    render() {
        const {
            slice,
            children,
            cb,
            handleTabClick,
            breakpoint,
            ...rest
        } = this.props;
        return children.slice(slice[0], slice[1]).map((child, i) => {
            i = i + slice[0];
            // console.log(slice[0], i, child.props.heading);
            return (
                <AccordionHeader
                    onClick={() => handleTabClick(child, cb(i, breakpoint))}
                    key={"header" + i}
                    i={i}
                    {...rest}
                >
                    <AccordionHeading>{child.props.heading}</AccordionHeading>
                </AccordionHeader>
            );
        });
    }
}

Tabordion.displayName = "Tabordion";

Tabordion.propTypes = {
    active: PropTypes.number,
    barClickable: PropTypes.bool,
    allowMultiple: PropTypes.bool,
    type: PropTypes.oneOf(["tabs", "accordion", "steps"]),
    respondsAt: PropTypes.number,
    unmounts: PropTypes.bool,
    responsive: PropTypes.bool,
    /** Callback function for when tab is clicked */
    onTabClick: PropTypes.func
};

Tabordion.defaultProps = {
    active: null,
    barClickable: true,
    allowMultiple: false,
    respondsAt: 600,
    type: "tabs",
    unmounts: true,
    responsive: true,
    showDivider: false
};

export default Tabordion;
