import React from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import { selectionIsEmpty } from "@cortexglobal/cortex-utilities";
import { Button, Row, Column } from "../../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { Link } from "react-router-dom";

const flattenOptions = (options, flattenedOptions = [], key = "children") => {
    options.forEach(option => {
        flattenedOptions.push(option);
        if (option[key] && Array.isArray(option[key])) {
            flattenOptions(option[key], flattenedOptions, key);
        }
    });
    return flattenedOptions;
};

let FilterSummary = ({
    filters,
    selectedFilters,
    onUpdate,
    onClear,
    separator,
    searchTerm,
    searchClear
}) => {
    const selectedFilterArray = Object.keys(selectedFilters);

    /**
     * Checks to see if the filters are empty
     * @param {object} selectedFilters
     */
    const filtersAreEmpty = selectedFilters => {
        let emptyFilters = true;
        const selectedFilterArray = Object.keys(selectedFilters);

        //If the selectedFilterArray is empty there are no filters so no need to update
        if (selectedFilterArray.length > 0) {
            for (
                let i = 0;
                i < selectedFilterArray.length && emptyFilters;
                i++
            ) {
                const selectedOptions = selectedFilters[selectedFilterArray[i]];
                if (selectedOptions === null) {
                    continue;
                }

                if (Array.isArray(selectedOptions) && selectedOptions > 0) {
                    emptyFilters = false;
                } else if (selectedOptions > 0) {
                    emptyFilters = false;
                } else if (selectedOptions.length > 0) {
                    emptyFilters = false;
                }
            }
        }

        return emptyFilters;
    };

    /**
     * Used to render out the selected option(s)
     * @param {*} selectedFilter
     * @param {*} selectedFilterOption
     * @param {*} filterDetail
     * @param {*} addSeparator
     */
    const renderOptions = (
        selectedFilterOption,
        filterDetail,
        addSeparator = false
    ) => {
        console.log("renderOptions", filterDetail, selectedFilterOption);

        if (Array.isArray(selectedFilterOption)) {
            return selectedFilterOption.map((selectedOption, index) => {
                const optionDetail = filterDetail.options.find(({ value }) => {
                    return value === selectedOption;
                });

                return renderOptions(
                    selectedOption,
                    optionDetail,
                    index + 1 < selectedFilterOption.length
                );
            });
        }

        return (
            <React.Fragment key={selectedFilterOption}>
                {" "}
                {filterDetail && filterDetail.text
                    ? filterDetail.text
                    : selectedFilterOption}
                {addSeparator && separator}
            </React.Fragment>
        );
    };

    if (filtersAreEmpty(selectedFilters) && !searchTerm) {
        return null;
    }

    return (
        <Row style={{ marginTop: "1rem" }}>
            <Column medium={2}>
                <h4 style={{ paddingBottom: ".5rem" }}>
                    {trans("Applied Filters")}
                </h4>
            </Column>
            <Column medium={8}>
                {selectedFilterArray.map(selectedFilter => {
                    const selectedOptions = selectedFilters[selectedFilter];
                    console.log(filters, selectedFilter);
                    let filterDetail = filters.filter(
                        filter => filter.name === selectedFilter
                    )[0];
                    console.log(filterDetail);
                    //Check we've got filterDetail and if there's any options selected
                    //as we only want to show options that can be de-selected
                    if (filterDetail && !selectionIsEmpty(selectedOptions)) {
                        //If the filter type is a tree we need to flatten the options
                        if (filterDetail.type === "CheckboxTree") {
                            filterDetail = {
                                ...filterDetail,
                                options: flattenOptions(filterDetail.options)
                            };
                        }

                        return (
                            <Link
                                style={{
                                    marginRight: "0.5rem",
                                    textDecoration: "none"
                                }}
                                height={28}
                                key={selectedFilter}
                                onClick={() => onUpdate(selectedFilter)}
                            >
                                {filterDetail.label}:
                                <strong>
                                    {renderOptions(
                                        selectedOptions,
                                        filterDetail
                                    )}{" "}
                                </strong>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Link>
                        );
                    } else {
                        return null;
                    }
                })}
                {searchTerm && (
                    <Link
                        style={{
                            marginRight: "0.5rem",
                            textDecoration: "none"
                        }}
                        height={28}
                        onClick={searchClear}
                    >
                        <span>{trans("Search")}: </span>
                        <strong style={{ marginRight: "0.8rem" }}>
                            {searchTerm}
                        </strong>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Link>
                )}
            </Column>
            <Column medium={2}>
                <Button height={28} onClick={() => onClear()}>
                    {trans("Clear All")}{" "}
                    <FontAwesomeIcon icon={faTimesCircle} />
                </Button>
            </Column>
        </Row>
    );
};

FilterSummary = withTheme(FilterSummary);

FilterSummary.displayName = "FilterSummary";

FilterSummary.propTypes = {
    /** Filters: Need to be of a certain shape, but this needs to be worked out.*/
    filters: PropTypes.array.isRequired,
    selectedFilters: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    separator: PropTypes.string
};

FilterSummary.defaultProps = {
    separator: ","
};

export default FilterSummary;
