import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const MainNavLink = styled(NavLink).attrs({
    // exact: true,
    activeClassName: "active"
})`
    display: inline-block;
    margin: 0 ${props => props.theme.navigation.navLink.margin}em;
    padding: ${props => props.theme.navigation.navLink.padding}em 0;
    text-decoration: none;
    color: ${props => props.theme.navigation.navLink.color};
    text-transform: ${props => props.theme.navigation.navLink.textTransform};
    position: relative;
    font-family: ${props => props.theme.navigation.navLink.fontFamily};
    font-weight: ${props => props.theme.navigation.navLink.fontWeight};
    font-size: ${props => props.theme.navigation.navLink.fontSize}px;
    text-rendering: optimizeLegibility;

    &.active, :hover {
        color: ${props => props.theme.navigation.navLink.activeColor};
        text-decoration: ${props =>
            props.theme.navigation.navLink.activeTextDecoration};
    }
    /* &:not(:last-child):after {
        content: " ";
        right: -${props => props.theme.navigation.navLink.margin}em;
        top: 50%;
        height: 25%;
        position: absolute;
        border-right: 1px solid;
        transform: translateY(-50%);
        opacity: 0.2;
    } */
`;

MainNavLink.displayName = "MainNavLink";

MainNavLink.propTypes = {};

MainNavLink.defaultProps = {};
export default MainNavLink;
