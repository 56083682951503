import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { shade } from "@cortexglobal/cortex-utilities";
import { foregroundColor } from "@cortexglobal/cortex-utilities";

const background = props =>
    props.theme.panel.background ||
    props.theme.colors[props.type] ||
    props.theme.colors[props.theme.panel.default];

const color = props =>
    props.theme.panel.color ||
    foregroundColor(
        background(props),
        0.75,
        props.theme.lightColor,
        props.theme.darkColor
    );

export const StyledPanel = styled.div`
    color: ${color};
    background-color: ${props => background(props)};
    margin-bottom: ${props =>
        props.marginBottom != undefined
            ? props.marginBottom
            : props.theme.spacing.margin}em;
    padding-top: ${props =>
        props.padding != undefined
            ? props.padding
            : props.theme.spacing.padding}rem;
    padding-bottom: ${props =>
        props.padding != undefined
            ? props.padding
            : props.theme.spacing.padding}rem;
    box-shadow: ${props => props.theme.panel.boxShadow};
    border-radius: ${props => props.theme.panel.radius}px;

    ${props => props.heading && `padding-top: 0`};
`;

const Heading = styled.div`
    padding: 12px ${props => props.theme.spacing.padding}em;
    color: inherit;
    background: ${props => shade(background(props), -10)};
    border-radius: ${props => props.theme.panel.radius}px
        ${props => props.theme.panel.radius}px 0 0;
    margin-bottom: ${props =>
        props.padding != undefined
            ? props.padding
            : props.theme.spacing.padding}em;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
`;

const Panel = ({ children, heading, padding, type, ...rest }) => (
    <StyledPanel heading={heading} padding={padding} type={type} {...rest}>
        {heading && (
            <Heading padding={padding} type={type}>
                {heading}
            </Heading>
        )}
        {children}
    </StyledPanel>
);

Panel.displayName = "Panel";

Panel.propTypes = {
    type: PropTypes.string,
    marginBottom: PropTypes.number,
    padding: PropTypes.number,
    margin: PropTypes.number
};

Panel.defaultProps = {};

export default Panel;
