import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const FileIcon = styled.div`
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0.2rem 0.4rem;
    width: 4rem;
    height: 5.5rem;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ color }) => color} !important;
    position: relative;
    border-radius: 4px;
    font-size: 0.8rem;
    text-decoration: none;
    text-transform: uppercase;

    //Adds the folded corner
    :before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-bottom-left-radius: 2px;
        border-width: 5px;
        border-style: solid;
        border-color: ${({ colorBehind }) => colorBehind}
            ${({ colorBehind }) => colorBehind} rgba(255, 255, 255, 0.4)
            rgba(255, 255, 255, 0.4);
    }
`;

FileIcon.displayName = "FileIcon";

FileIcon.propTypes = {
    /** The color the icon is to be placed in front of, needed to cover up the corner */
    colorBehind: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    color: PropTypes.string
};

FileIcon.defaultProps = {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    color: "#fff"
};

export default FileIcon;
