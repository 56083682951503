import React from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import { Button, Row, Column } from "../../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";

const FilterRowWrapper = styled.div`
    // display: flex;
    // justify-content: flex-end;
    background-color: ${({ theme }) => theme.filters.row.backgroundColor};
`;
const FilterRow = styled(Row)`
    display: flex;
    justify-content: flex-end;
    padding: 0.8rem;
`;
let ApplyFiltersRow = ({ filters, selectedFilters, onApply, onClear }) => {
    // console.log(selectedFilters);
    const noFiltersSelected = Object.keys(selectedFilters).length === 0;
    return (
        <FilterRowWrapper>
            <FilterRow>
                <Button
                    height={36}
                    onClick={onClear}
                    disabled={noFiltersSelected}
                    style={{ marginRight: "1rem" }}
                >
                    {trans("Clear Filters")}{" "}
                    <FontAwesomeIcon icon={faTimesCircle} />
                </Button>
                <Button
                    height={36}
                    onClick={onApply}
                    disabled={noFiltersSelected}
                >
                    {trans("Apply")}
                </Button>
            </FilterRow>
        </FilterRowWrapper>
    );
};

ApplyFiltersRow = withTheme(ApplyFiltersRow);

ApplyFiltersRow.displayName = "ApplyFiltersRow";

ApplyFiltersRow.propTypes = {
    onApply: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired
};

ApplyFiltersRow.defaultProps = {
    separator: ","
};

export default ApplyFiltersRow;
