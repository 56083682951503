import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import styled, { withTheme } from "styled-components";
import MainNavLink from "./MainNavLink";
import { MainNavWrapper, Row, Column } from "../../index";

const SubNavLink = styled(MainNavLink)`
    font-family: ${props => props.theme.navigation.subNavLink.fontFamily};
    font-size: ${props => props.theme.navigation.subNavLink.fontSize}px;
    text-transform: ${props => props.theme.navigation.subNavLink.textTransform};
    font-weight: ${props => props.theme.navigation.subNavLink.fontWeight};
    color: ${props => props.theme.navigation.subNavLink.color};

    &.active,
    :hover {
        color: ${props => props.theme.navigation.subNavLink.activeColor};
        text-decoration: ${props =>
            props.theme.navigation.subNavLink.activeTextDecoration};
    }
`;

const SubMenuWrapper = styled.div`
    background: ${props => props.theme.navigation.subNavigation.background};
    box-shadow: ${props => props.theme.navigation.subNavigation.boxShadow};
`;
const Container = styled.div`
    font-size: ${props => props.size};
    font-weight: ${props => props.weight};
    background: ${props => props.theme.navigation.background};
    margin: ${props => props.margin};
    /* box-shadow: ${props => props.theme.navigation.boxShadow}; */
`;

const MainNavColumn = styled(Column)`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 850px;
    a:first-child {
        margin-left: 0;
    }
`;

class MainNavMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.navigation !== this.props.navigation) {
            const {
                navigation: { data },
                location
            } = this.props;

            // console.log(this.props, location);
            if (!location) {
                return;
            }
            const currentPath = location.pathname;
            data.filter(item => {
                // console.log(item);
                return item.level === 0;
            }).forEach(item => {
                // console.log(item);
                const currentSection = currentPath.split("/")[1];
                const navItemSection = item.path.split("/")[1];

                if (currentSection === navItemSection) {
                    // console.log(currentPath, item.path, "Updating");
                    this.updateSection(item.id);
                }
            });
        }
    }
    updateSection = selectedId => {
        const {
            navigation: { data }
        } = this.props;
        let children = [];
        const selectedItem = data.find(({ id }) => selectedId === id);
        if (selectedItem.parent_id > 0) {
            children = data.filter(item => {
                return item.parent_id === selectedId;
            });
        }
        this.setState({ section: selectedId, children: children });
    };

    render() {
        const {
            navigation: { data, loaded },
            theme: {
                navigation: { showMobileMenuBelow }
            }
        } = this.props;
        const { width } = this.state;

        if (width < showMobileMenuBelow) {
            return null;
        }
        return (
            <Container {...this.props}>
                {" "}
                {loaded && (
                    <MainNavWrapper>
                        <Row>
                            <MainNavColumn>
                                {data
                                    .filter(item => {
                                        return item.level === 0;
                                    })
                                    .map(item => {
                                        // console.log(key, item);
                                        return (
                                            <MainNavLink
                                                exact={item.parent_id === null}
                                                key={item.id}
                                                to={item.path}
                                                onClick={this.updateSection.bind(
                                                    this,
                                                    item.id
                                                )}
                                            >
                                                {item.name}
                                            </MainNavLink>
                                        );
                                    })}
                            </MainNavColumn>
                        </Row>
                    </MainNavWrapper>
                )}
                {this.state.children && (
                    <SubMenuWrapper>
                        <Row>
                            <Column
                                style={{ fontSize: "8pt", boxShadow: "-2" }}
                            >
                                {this.state.children.map(child => {
                                    return (
                                        <SubNavLink
                                            key={child.id}
                                            to={child.path}
                                        >
                                            {child.name}
                                        </SubNavLink>
                                    );
                                })}
                            </Column>
                        </Row>
                    </SubMenuWrapper>
                )}
            </Container>
        );
    }
}

MainNavMenu.displayName = "MainNavMenu";

MainNavMenu.propTypes = {
    /** Font color - A valid css color value */
    color: PropTypes.string,
    /** Font size - A valid css size value */
    size: PropTypes.string,
    /** Font weight - A valid css weight value */
    weight: PropTypes.string,
    /** The css background property for the navigation container */
    background: PropTypes.string,
    /** The css margin property for the navigation container */
    margin: PropTypes.string,
    marginOnFirstItem: PropTypes.bool
};

MainNavMenu.defaultProps = {
    size: "1em",
    weight: "bold",
    margin: `0 0 0 0`,
    marginOnFirstItem: false
};

export default withRouter(withTheme(MainNavMenu));
