import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import DatePickerInputField from "./DatePickerInputField";
import CalendarContainer from "./CalendarContainer";

class StyledDatePicker extends Component {
    getValue(props) {
        if (props.allowEmpty && props.selected == "") {
            return null;
        }
        return typeof props.selected === "string"
            ? moment(props.selected).toDate()
            : props.selected;
    }

    render = () => {
        return (
            <ReactDatePicker
                {...this.props}
                selected={this.getValue(this.props)}
                customInput={
                    <DatePickerInputField dateFormat={this.props.dateFormat} />
                }
                onSelect={value => {
                    this.props.onChange(value);
                }}
                className={`form-control ${this.props.error ? "alert" : ""}`}
                popperContainer={CalendarContainer}
                popperClassName="showInFront"
                autoComplete="off"
                placeholderText={this.props.placeholder}
            />
        );
    };
}

StyledDatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    initialValue: PropTypes.any,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    emptyOption: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    dateFormat: PropTypes.string,
    placeholder: PropTypes.string,
    allowEmpty: PropTypes.bool
};
StyledDatePicker.defaultProps = {
    error: "",
    expanded: true,
    dateFormat: "DD/MM/YYYY",
    placeholder: "DD/MM/YYYY",
    // selected: null, // moment(),
    allowEmpty: false
};
export default StyledDatePicker;
