import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import BaseInput from "./baseInput";
import CloseButton from "../../closeButton";
// const InputWrapper = styled.span`
//     display: inline-block;
//     position: relative;
// `;
// const ClearButton = styled(CloseButton)`
//     position: absolute;
//     top: 0;
//     right: 0;
// `;
const types = [
    "date",
    "datetime-local",
    "email",
    "hidden",
    "month",
    "number",
    "password",
    "search",
    "tel",
    "text",
    "time",
    "week"
];

// export const StyledBaseInput = styled(BaseInput)`
export const StyledInput = styled(BaseInput)`
    ${props =>
        types.indexOf(props.type) !== -1 &&
        css`
            ::placeholder {
                color: ${props => props.theme.input.color};
                opacity: 0.5;
            }
        `};
`;

// export const StyledInput = ({ type, showClearButton = false, ...rest }) => {
//     const inputEl = useRef(null);

//     const clear = () => {
//         inputEl.current.value = "";
//         // this.refs.input.value = "";
//         const event = new Event("input", { bubbles: true });
//         inputEl.current.dispatchEvent(event);
//     };
//     return (
//         <InputWrapper {...rest}>
//             <StyledBaseInput
//                 type={type}
//                 showClearButton={showClearButton}
//                 ref={inputEl}
//                 {...rest}
//             ></StyledBaseInput>
//             {showClearButton ||
//                 (type === "search" && <ClearButton onClick={clear} />)}
//         </InputWrapper>
//     );
// };

StyledInput.displayName = "StyledInput";

StyledInput.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    input: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    height: PropTypes.number,
    labelWidth: PropTypes.number,
    labelAlign: PropTypes.string
};

StyledInput.defaultProps = {
    size: "default",
    type: "text",
    readOnly: false,
    error: false,
    height: 38
};

export default StyledInput;
