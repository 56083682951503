import React from "react";
import styled from "styled-components";
import { Panel, Image, IconTextButton } from "../../index";
import Badge from "../badge";

export const SearchResultWrapper = styled(Panel)`
    box-shadow: ${({ boxShadow, theme }) =>
        boxShadow || theme.search.boxShadow};
    background: ${({ background, theme }) =>
        background || theme.search.background};
    transition: all 200ms;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    aside {
        color: ${({ theme }) => theme.search.summary.color};
    }
    button,
    aside {
        transition: color 200ms;
    }
    &:hover {
        box-shadow: ${({ boxShadow, theme }) =>
            boxShadow || theme.search.boxShadowHover};
        button,
        aside {
            color: ${({ theme }) => theme.search.hoverColor};
        }
    }
`;
export const SearchResultImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    height: ${({ theme }) => theme.search.image.height};
    background: ${({ theme }) => theme.search.image.background};
`;

export const SearchResultImage = styled(Image)`
    width: 100%;
    height: 100%;
    /* width: 95%;
    height: 90%; */
    padding: ${({ theme }) => theme.search.image.padding};
    object-fit: ${({ objectFit, theme }) =>
        objectFit || theme.search.image.objectFit};

    position: relative;
`;

export const SearchResultButton = styled(IconTextButton)`
    width: 100%;
    height: 100%;
    padding: 1rem 0.4rem 1rem 0.2rem;
    position: relative;
`;
export const SearchResultTitle = styled.h5`
    margin: 0;
    font-size: 1rem;
    text-align: left;
    /* width: 90%; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    max-height: 2.4rem;
`;

export const SearchResultSummary = styled.aside`
    margin-top: 0.5rem;
    font-size: 0.8rem;
    font-weight: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: noWrap;
    color: #403e37 !important;
`;

export const SearchResultBadge = styled(Badge)`
    display: ${({ showBadge, theme, badgeType }) =>
        showBadge
            ? "block"
            : theme.search.badge.badgeType[badgeType] &&
              theme.search.badge.badgeType[badgeType].display
            ? theme.search.badge.badgeType[badgeType].display
            : theme.search.badge.show
            ? "block"
            : "none"};
    position: absolute;
    top: 0;
    right: 0;
    text-transform: ${({ theme }) => theme.search.badge.textTransform};
    color: ${({ theme, badgeType }) =>
        theme.search.badge.badgeType[badgeType] &&
        theme.search.badge.badgeType[badgeType].color
            ? theme.search.badge.badgeType[badgeType].color
            : theme.search.badge.badgeType["default"].color};
    background: ${({ theme, badgeType }) =>
        theme.search.badge.badgeType[badgeType] &&
        theme.search.badge.badgeType[badgeType].background
            ? theme.search.badge.badgeType[badgeType].background
            : theme.search.badge.badgeType["default"].background};
`;
