import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const InputErrorPanel = styled.span`
    font-size: 0.7rem;
    margin-top: ${({ marginTop, error }) =>
        typeof error === "boolean" ? 0 : marginTop};
    display: block;
    color: ${props => props.theme.input.error.color};
`;

const InputError = props => {
    // console.log(typeof props.error);
    // if (typeof props.error !== "string" || typeof props.error !== "array") {
    if (!props.error) {
        return null;
    }
    return (
        <InputErrorPanel style={props.errorStyle} {...props}>
            {props.error}
        </InputErrorPanel>
    );
};

InputError.displayName = "InputError";

InputError.propTypes = {
    // error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

InputError.defaultProps = {
    error: "",
    marginTop: "-0.8rem",
};

export default InputError;
