import React from "react";
import { v4 as uuid4 } from "uuid";
const ToastStateContext = React.createContext();
const ToastDispatchContext = React.createContext();

const initialToasts = [];

const toastReducer = (state, action) => {
    switch (action.type) {
        case "addToast": {
            //Add a uuid to the toast if one was not defined
            let newToast = action.toast;
            if (typeof newToast === "undefined") {
                throw new Error(`You need to provide a toast`);
            }
            if (typeof newToast.uuid === "undefined") {
                newToast.uuid = uuid4();
            }
            return [...state, action.toast];
        }
        case "removeToast": {
            //Ensure a uuid has been provided
            if (typeof action.uuid === "undefined") {
                throw new Error(`You need to provide the toast UUID`);
            }

            const index = state.findIndex(toast => toast.uuid === action.uuid);

            return [...state.slice(0, index), ...state.slice(index + 1)];
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

const ToastProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(toastReducer, initialToasts);
    return (
        <ToastStateContext.Provider value={state}>
            <ToastDispatchContext.Provider value={dispatch}>
                {children}
            </ToastDispatchContext.Provider>
        </ToastStateContext.Provider>
    );
};

const useToastState = () => {
    const context = React.useContext(ToastStateContext);
    if (context === undefined) {
        throw new Error("useToastState must be used within a ToastProvider");
    }
    return context;
};
const useToastDispatch = () => {
    const dispatch = React.useContext(ToastDispatchContext);
    if (dispatch === undefined) {
        throw new Error("useToastDispatch must be used within a ToastProvider");
    }
    return dispatch;
};
const useAddToast = () => {
    const dispatch = React.useContext(ToastDispatchContext);
    if (dispatch === undefined) {
        throw new Error("useToastDispatch must be used within a ToastProvider");
    }
    return toast => {
        return dispatch({ type: "addToast", toast: toast });
    };
};
const useToasts = () => {
    return [useToastState(), useToastDispatch()];
};

export {
    ToastProvider,
    useToastState,
    useToastDispatch,
    useAddToast,
    useToasts
};
