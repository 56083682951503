import React from "react";
import PropTypes from "prop-types";
import styled, { withComponent } from "styled-components";
import { shade } from "@cortexglobal/cortex-utilities";
import FormLabel from "./label";
import InputError from "./inputError";
import { baseInputStyle } from "./baseInput";

const backgroundImage = (size, arrowScale, arrowColor, backgroundColor) => {
    return encodeURI(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <rect width="${size}" height="${size}" style="fill: ${backgroundColor}"/>
        <path d="M0,6.41l-10-10,2.83-2.83L0,.76,7.17-6.41,10-3.59Z" style="transform: translate(50%, 50%) scale(${arrowScale}); fill: ${arrowColor};"/>
    </svg>`);
};

// let Select = styled.select`
//     ${baseInputStyle}
//     background-image: url("data:image/svg+xml;utf8,${props =>
//         backgroundImage(
//             props.height,
//             props.theme.input.iconScale / 1.5,
//             props.theme.input.iconColor,
//             props.theme.input.iconBackground
//         )}");
//     background-origin: content-box;
//     background-position: right -${props => props.height + 5}px  center;
//     background-repeat: no-repeat;
//     background-size: ${props => props.height}px ${props => props.height}px;
//     appearance: none;
//     padding-right: ${props => props.height + 5}px;
//     cursor: pointer;
// `;

let Select = styled.select`
    ${baseInputStyle}
    cursor: pointer;
`;

Select = Select.withComponent("select");

const StyledSimpleSelect = ({
    options,
    type,
    name,
    onChange,
    label,
    inlineLabel,
    emptyOption,
    showEmptyOption,
    readOnly,
    error,
    labelWidth,
    labelAlign,
    wrapperProps,
    ...rest
}) => {
    return (
        <Select
            id={name}
            name={name}
            onChange={onChange}
            labelWidth={labelWidth}
            labelAlign={labelAlign}
            error={error}
            {...rest}
        >
            {showEmptyOption && <option value="">{emptyOption}</option>}

            {options &&
                options.map((opt, index) => {
                    if (opt.type && opt.type == "optgroup") {
                        return <optgroup label={opt.text} key={index} />;
                    }
                    return (
                        <option value={opt.value} key={index}>
                            {opt.text}
                        </option>
                    );
                })}
        </Select>
    );
};

StyledSimpleSelect.displayName = "StyledSimpleSelect";

StyledSimpleSelect.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            text: PropTypes.string.isRequired,
            type: PropTypes.string
        })
    ),
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    emptyOption: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    height: PropTypes.number,
    labelWidth: PropTypes.number,
    labelAlign: PropTypes.string
};
StyledSimpleSelect.defaultProps = {
    size: "default",
    emptyOption: "— Select One —",
    showEmptyOption: true,
    error: "",
    height: 38
};
export default StyledSimpleSelect;
