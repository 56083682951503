import React from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { isJSON } from "@cortexglobal/cortex-utilities/lib/helpers";

export const DraftJSOutput = ({ children }) => {
    if (!isJSON(children)) {
        return children;
    }
    const storedState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(children))
    );
    return (
        <div className="readonly-editor">
            <Editor
                editorState={storedState}
                readOnly={true}
                toolbarHidden={true}
            />
        </div>
    );
};

export default DraftJSOutput;
