import React from "react";
import ReactDOM from "react-dom";

import ReactTooltip from "react-tooltip-lite";

import styled, { withTheme, css } from "styled-components";
import { isBrowser } from "@cortexglobal/cortex-utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";

const Icon = () => {
    return <FontAwesomeIcon icon={faInfoCircle} />;
};

const StyledTooltip = styled(ReactTooltip).attrs({
    role: "tooltip"
})`
    opacity: ${({ theme }) => theme.tooltip.opacity};

    padding: ${({ theme }) => theme.tooltip.padding};
    margin: ${({ theme }) => theme.tooltip.margin};

    .react-tooltip-lite {
        border-radius: ${({ theme }) => theme.tooltip.radius};
        ${({ setMaxWidth, maxWidth }) =>
            setMaxWidth &&
            css`
                max-width: ${maxWidth} !important;
            `}
    }
`;

const Tooltip = ({
    text,
    children,
    theme,
    showIcon = true,
    setMaxWidth = true,
    maxWidth = "20rem",
    ...rest
}) => {
    return (
        <StyledTooltip
            tagName="span"
            content={text}
            background={theme.tooltip.background}
            color={theme.tooltip.color}
            hoverDelay={100}
            showIcon={showIcon}
            setMaxWidth={setMaxWidth}
            maxWidth={maxWidth}
            {...rest}
        >
            {children} {showIcon && <Icon />}
        </StyledTooltip>
    );
};

Tooltip.displayName = "Tooltip";

export default withTheme(Tooltip);
