import React, { useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { Transition } from "react-transition-group";
import Measure from "react-measure";

const StyledOverlay = styled.div`
    position: ${({ fixed }) => (fixed ? "fixed" : "absolute")};
    z-index: ${({ zIndex }) => zIndex};
    top: 0;
    left: 0;
    width: 100%;
    height: ${({ fixed }) => (fixed ? "100vh" : "100%")} !important;
    background: ${({ theme }) => theme.panel.overlay};
    transition: all ${({ theme }) => theme.animations.duration.medium}ms;
    opacity: ${({ animationState }) => {
        const value =
            animationState === "entering" || animationState === "entered"
                ? 0.5
                : 0;

        return value;
    }};
`;
const StyledSidePanel = styled.aside`
    position: ${({ fixed }) => (fixed ? "fixed" : "absolute")};
    z-index: ${({ zIndex }) => zIndex + 1};
    width: ${({ width }) => width};
    height: ${({ fixed }) => (fixed ? "100vh" : "100%")};
    top: 0;
    background: ${({ theme }) => theme.panel.background};
    color: ${({ theme }) => theme.panel.color};
    ${({ alignment }) =>
        alignment === "right"
            ? css`
                  right: 0;
              `
            : css`
                  left: 0;
              `}
    transform: translateX(${({ animationState, width, alignment }) => {
        const endValue = alignment === "right" ? width : "-" + width;
        return animationState === "entering" || animationState === "entered"
            ? "0"
            : endValue;
    }}
  );
    transition: all ${({ theme }) => theme.animations.duration.medium}ms;
`;

const PanelContent = styled.div`
    height: ${({ contentHeight, panelHeight }) =>
        panelHeight > contentHeight ? contentHeight : panelHeight}px;

    overflow: auto;
    ${({ sticky }) =>
        sticky &&
        css`
            position: sticky;
            top: 0px;
        `};
`;

let SidePanel = ({
    visible,
    width,
    alignment,
    children,
    onClose,
    theme,
    sticky,
    fixed,
    zIndex,
    ...rest
}) => {
    //We need the content and panel heights
    //as the PanelContent elements' height
    //depends on which is the largest
    const [contentHeight, setContentHeight] = useState(0);
    const [panelHeight, setPanelHeight] = useState(0);

    const ref = useRef(null);

    useLayoutEffect(() => {
        if (ref.current) {
            setPanelHeight(ref.current.clientHeight);
        }
    });

    // console.log(contentHeight, panelHeight);
    return (
        <Transition
            in={visible}
            timeout={theme.animations.duration.medium}
            appear={true}
            unmountOnExit={true}
        >
            {animationState => (
                <div>
                    <StyledOverlay
                        animationState={animationState}
                        onClick={onClose}
                        zIndex={zIndex}
                        fixed={fixed}
                    ></StyledOverlay>
                    <StyledSidePanel
                        animationState={animationState}
                        width={width}
                        alignment={alignment}
                        ref={ref}
                        zIndex={zIndex}
                        fixed={fixed}
                    >
                        <PanelContent
                            contentHeight={contentHeight}
                            panelHeight={panelHeight}
                            sticky={sticky}
                        >
                            <Measure
                                bounds
                                onResize={contentRect =>
                                    setContentHeight(contentRect.bounds.height)
                                }
                            >
                                {({ measureRef }) => {
                                    return (
                                        <div ref={measureRef}>{children}</div>
                                    );
                                }}
                            </Measure>
                        </PanelContent>
                    </StyledSidePanel>
                </div>
            )}
        </Transition>
    );
};

SidePanel.displayName = "SidePanel";

SidePanel.propTypes = {
    /** Boolean indicating whether the panel should be showing */
    visible: PropTypes.bool,
    /** The width of the panel */
    width: PropTypes.string,
    /** Whether the panel should be on the left or right */
    alignment: PropTypes.oneOf(["left", "right"]),
    /** Boolean indicating whether the panel content should be sticky */
    sticky: PropTypes.bool,
    /** Boolean indicating whether the panel itself should be fixed */
    fixed: PropTypes.bool,
    /** Override the default z-index */
    zIndex: PropTypes.number
};

SidePanel.defaultProps = {
    visible: false,
    width: "300px",
    alignment: "right",
    sticky: true,
    fixed: false,
    zIndex: 1000
};

export default withTheme(SidePanel);
