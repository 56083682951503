import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";
import debounce from "lodash/debounce";

import { isJSON } from "@cortexglobal/cortex-utilities";

import {
    // Editor,
    EditorState,
    RichUtils,
    ContentState,
    convertFromRaw,
    convertToRaw
} from "draft-js";
// import "draft-js/dist/Draft.css";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { baseInputStyle } from "./baseInput";
import { transparentize } from "@cortexglobal/cortex-utilities";

const EditorWrapper = styled.div`
    ${baseInputStyle}
`;

const StyledEditor = styled(Editor)``;

const DraftJSField = ({
    name,
    value,
    onChange,
    label,
    readOnly,
    error,
    ...rest
}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (isJSON(value)) {
            setEditorState(
                EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
            );
        } else if (typeof value === "object") {
            setEditorState(
                EditorState.createWithContent(convertFromRaw(value))
            );
        }
    }, []);

    const theme = useTheme();

    const delayedOnChange = debounce(editorState => {
        onChange({
            name: name,
            value: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        });
    }, 500);

    const onEditorChange = editorState => {
        setEditorState(editorState);
        delayedOnChange(editorState);
    };

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onEditorChange(newState);
            return "handled";
        }
        return "not-handled";
    };
    return (
        <EditorWrapper>
            <StyledEditor
                id={name}
                name={name}
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                onEditorStateChange={onEditorChange}
                editorStyle={{
                    border: `1px solid  ${transparentize(
                        theme.input.borderColor,
                        0.7
                    )}`,
                    padding: "0 0.5em",
                    borderRadius: theme.input.radius,
                    background: theme.input.background,
                    marginBottom: "0.3rem"
                }}
                toolbarStyle={{ border: "none" }}
                // onChange={event => {
                //     console.log("event", event.getCurrentContent());
                //     onChange({
                //         name: name,
                //         value: JSON.stringify(
                //             convertToRaw(event.getCurrentContent())
                //         )
                //     });
                // }}
                {...rest}
            />
        </EditorWrapper>
    );
};

DraftJSField.displayName = "DraftJSField";

DraftJSField.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};
DraftJSField.defaultProps = {
    size: "default",
    expanded: false,
    inlineLabel: true,
    readOnly: false,
    error: ""
};
export default DraftJSField;
