import comparisons from "./comparisons";

/**
 * Check to see if the filters are actually empty (no real selections made)
 * @param {object} selectedFilterObject
 */
const checkFiltersAreEmpty = selectedFilterObject => {
    let filtersAreEmpty = true;
    const filterNames = Object.keys(selectedFilterObject);

    for (let i = 0; i < filterNames.length; i++) {
        const filterValue = selectedFilterObject[filterNames[i]];
        if (!selectionIsEmpty(filterValue)) {
            filtersAreEmpty = false;
            break;
        }
    }
    return filtersAreEmpty;
};

export const selectionIsEmpty = selection => {
    if (Array.isArray(selection)) {
        if (selection.length > 0) {
            // console.log("selection full array", selection);
            return false;
        }
    } else if (
        selection
        // selection !== null &&
        // selection !== "" &&
        // selection !== 0
    ) {
        // console.log("selection full string or number", selection);
        return false;
    }
    // console.log("selection empty", selection);

    return true;
};

export const filterObjects = (
    itemsToBeFiltered,
    filters,
    selectedFilterObject
) => {
    //Check that there's actually some selected filter criteria
    const filtersAreEmpty = checkFiltersAreEmpty(selectedFilterObject);
    //If there's no filters applied just return the original array
    // console.log("filtersAreEmpty", filtersAreEmpty);
    if (filtersAreEmpty) {
        return itemsToBeFiltered;
    }

    return itemsToBeFiltered.filter(item => {
        let keepItem = true;
        //Using loops as it should be more performant
        const selectedFilterNames = Object.keys(selectedFilterObject);
        for (let i = 0; i < selectedFilterNames.length; i++) {
            const selectedFilterValue =
                selectedFilterObject[selectedFilterNames[i]];
            //If this filter choice is actually empty don't try and filter by it
            if (selectionIsEmpty(selectedFilterValue)) {
                continue;
            }
            const selectedFilterName = selectedFilterNames[i];

            //Get the original filter item from the array of filters
            const filterItem = filters.find(filter => {
                return filter.alias === selectedFilterName;
            });

            //Get the comparison function as specified by the main filter item
            //or fallback on the "===" comparison
            const comparison =
                (filterItem && comparisons[filterItem.comparison]) ||
                comparisons["==="];
            //Perform the comparison, if it's set to false we can break
            //because there's no need to check the other filters as
            //it's turned off but this one
            keepItem = comparison(
                item[selectedFilterName],
                selectedFilterValue
            );
            if (!keepItem) {
                break;
            }
        }

        return keepItem;
    });
};
