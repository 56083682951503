import merge from "lodash/merge";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

// Colors
export const colors = {
    primary: "#d74540",
    secondary: "#283c56",
    accent: "#d6001c",

    //primaryLightest: "grey",
    // primaryLighter:
    // primaryLight:
    // primaryDark:
    // primaryDarker:
    // primaryDarkest:

    // secondaryLighter:
    // secondaryLighter:
    // secondaryLight:
    // secondaryDark:
    // secondaryDarker:
    // secondaryDarkest:

    //neutralDarker:
    neutralDark: "#707070",
    neutral: "#DFE0E1",
    neutralLight: "#F5F5F5",
    //neutralLighter:
    //neutralLightest:

    lightGray: "#f5f5f5",
    mediumGray: "#e8e8e8",
    darkGray: "#53565a",

    white: "#ffffff",
    gray100: "#f5f5f5",
    gray200: "#e8e8e8",
    gray300: "#cbc9c7",
    gray400: "#bbc0c7",
    gray500: "#aaafb7",
    gray600: "#9a9fa7",
    gray700: "#53565a",
    gray800: "#404346",
    gray900: "#2b2d2f",
    black: "#040404",

    alert: "#D54644",
    warning: "#ff9800",
    success: "#4caf50",
    info: "#00bcd4",
    background: "#e8e8e8",
    platinum: "#a2b8e4",
    gold: "#ffdb04",
    silver: "#dadada",
    bronze: "#ff8604",
    highlight: "#e6c2c1"
};

export const sizes = {
    default: "2",
    small: "1.2",
    large: "3"
};

export const spacing = {
    radius: "0.2",
    padding: "1.2",
    margin: "0.8"
};

export const breakpoints = {
    small: 0,
    medium: 500,
    large: 700,
    xlarge: 1050
};

const fonts = `
@font-face { font-family: "EuclidCircularA-Light"; src: url("/fonts/EuclidCircularA-Light.eot?") format("font-eot"), url("/fonts/EuclidCircularA-Light.woff2") format("font-woff2"), url("/fonts/EuclidCircularA-Light.woff") format("font-woff"), url("/fonts/EuclidCircularA-Light.otf") format("font-opentype"); }

@font-face { font-family: "EuclidCircularA-Bold"; src: url("/fonts/EuclidCircularA-Bold.eot?") format("font-eot"), url("/fonts/EuclidCircularA-Bold.woff2") format("font-woff2"), url("/fonts/EuclidCircularA-Bold.woff") format("font-woff"), url("/fonts/EuclidCircularA-Bold.otf") format("font-opentype"); }

@font-face { font-family: "EuclidCircularA-Medium"; src: url("/fonts/EuclidCircularA-Medium.eot?") format("font-eot"), url("/fonts/EuclidCircularA-Medium.woff2") format("font-woff2"), url("/fonts/EuclidCircularA-Medium.woff") format("font-woff"), url("/fonts/EuclidCircularA-Medium.otf") format("font-opentype"); }

@font-face { font-family: "EuclidCircularA-Regular"; src: url("/fonts/EuclidCircularA-Regular.eot?") format("font-eot"), url("/fonts/EuclidCircularA-Regular.woff2") format("font-woff2"), url("/fonts/EuclidCircularA-Regular.woff") format("font-woff"), url("/fonts/EuclidCircularA-Regular.otf") format("font-opentype"); }

@font-face { font-family: "EuclidCircularA-SemiBold"; src: url("/fonts/EuclidCircularA-SemiBold.eot?") format("font-eot"), url("/fonts/EuclidCircularA-SemiBold.woff2") format("font-woff2"), url("/fonts/EuclidCircularA-SemiBold.woff") format("font-woff"), url("/fonts/EuclidCircularA-SemiBold.otf") format("font-opentype"); } 
`;
const font = {
    fontLight: "'EuclidCircularA-Light', sans-serif",
    fontBody: "'EuclidCircularA-Regular', sans-serif",
    fontMedium: "'EuclidCircularA-Medium', sans-serif",
    fontSemibold: "'EuclidCircularA-SemiBold', sans-serif",
    fontBold: "'EuclidCircularA-Bold', sans-serif",
    fontHeading: "'EuclidCircularA-Bold', sans-serif"
};
export const logo = `
/img/cortex-logo-black@2x.png
`;
export const planner = {
    colors: {
        activities: {
            parent: "#75abf5",
            child: colors.success,
            self: colors.warning,
            activity: colors.primary,
            taskBackgroundGenerator: ({ baseColor, transparentizedColor }) => {
                return `repeating-linear-gradient(
                    -45deg,
                    ${baseColor},
                    ${baseColor} 5px,
                    ${transparentizedColor} 5px,
                    ${transparentizedColor} 10px
                  )`;
            },
            activityBackgroundGenerator: ({ baseColor }) => {
                return `${baseColor}`;
            }
        },
        // activities: {
        //     national: colors.primary,
        //     plan: colors.gray800,
        //     group: colors.success
        // },
        header: {
            label: colors.lightGray,
            button: colors.lightGray,
            background: colors.black
        },
        micro: {
            label: colors.mediumGray,
            current: colors.white,
            currentBackground: colors.gray700,
            line: "rgba(0, 0, 0, 0.1)"
        },
        calendarCategory: {
            color: colors.white,
            background: colors.black
        },
        taskCategory: {
            label: colors.black,
            button: colors.secondary,
            border: colors.black,
            background: colors.gray400,
            color: colors.black,
            hoverColor: colors.white
        },
        taskRow: {
            label: colors.darkGray,
            labelHover: colors.black,
            buttonHover: colors.gray200,
            background: colors.white,
            sidebar: colors.gray100
        }
        /**
         * This whole section can be omitted and the taskRow sidebar styling will be used
         */
        // summaryColumn: {
        //     /**
        //      * This header section can be omitted and the general header styling will be used
        //      */
        //     header: {
        //         color: colors.white,
        //         background: colors.secondary
        //     },
        //     content: {
        //         color: colors.white,
        //         background: colors.secondary
        //     }
        // }
    },
    heights: {
        row: 26,
        task: 20,
        activity: 20
    }
};

let animations = {
    duration: {
        short: 250,
        medium: 400,
        long: 550
    },
    types: {
        bounce: "cubic-bezier(0.68, -0.55, 0.265, 2.05)"
    }
};

animations.presets = {
    shortBounce: `${animations.duration.short}ms ${animations.types.bounce}`,
    mediumBounce: `${animations.duration.medium}ms ${animations.types.bounce}`,
    longBounce: `${animations.duration.long}ms ${animations.types.bounce}`
};

export const defaultThemeOptions = {
    animations: animations,
    breakpoints: breakpoints,
    colors: colors,
    fonts: fonts,
    font: font,
    sizes: sizes,
    spacing: spacing
};

export const generateTheme = (siteOptions = {}, siteTheme = {}) => {
    const options = merge(defaultThemeOptions, siteOptions);
    const {
        animations,
        breakpoints,
        colors,
        fonts,
        font,
        sizes,
        spacing
    } = options;
    const defaultTheme = {
        //General Setup (alphabetical)
        animations: animations,
        breakpoints: breakpoints,
        colors: colors,
        fonts: fonts,
        sizes: sizes,
        spacing: spacing,

        //Specific component/area theming (alphabetical)

        alert: {
            borderWidth: 2,
            borderRadius: 0.25,
            default: "primary",
            title: {
                fontSize: 18,
                fontWeight: "bold",
                textTransform: "uppercase"
            },
            text: {
                fontSize: 14,
                fontWeight: "normal",
                textTransform: "normal"
            }
        },
        anchor: {
            color: colors.black
        },
        asset: {
            groupAvailability: {
                multi: true
            }
        },
        badge: {
            color: colors.white,
            display: "inline-block",
            borderRadius: 2
        },
        body: {
            color: colors.black,
            background: colors.background,
            fontFamily: font.fontBody,
            fontSize: 16
        },
        breadcrumb: {
            background: colors.neutralLight,
            crumb: {
                linkColor: colors.secondary,
                fontSize: "0.7rem"
            }
        },
        button: {
            fontSize: 14,
            minHeight: "2rem",
            borderWidth: 2,
            borderRadius: 0,
            fontWeight: "bold",
            textTransform: "none",
            textAlign: "left",
            default: "primary",
            hoverColor: colors.accent,
            types: {
                iconText: {
                    icon: "chevron-right",
                    boxShadow: "none",
                    foregroundColor: colors.black,
                    backgroundColor: colors.white,
                    backgroundAlpha: 0,
                    padding: 0,
                    textTransform: "none"
                },
                default: {
                    hollow: false,
                    foregroundColor: colors.white,
                    backgroundColor: colors.secondary,
                    padding: 2
                },
                submit: {
                    hollow: false,
                    foregroundColor: colors.white,
                    backgroundColor: colors.secondary
                },
                secondary: {
                    hollow: false,
                    foregroundColor: colors.white,
                    backgroundColor: colors.gray700
                },
                alert: {
                    hollow: false,
                    foregroundColor: colors.white,
                    backgroundColor: colors.alert
                },
                linkStyle: {
                    boxShadow: "none",
                    textDecoration: "underline",
                    foregroundColor: colors.black,
                    backgroundColor: colors.secondary,
                    backgroundAlpha: 0,
                    padding: 0,
                    textTransform: "none"
                }
            }
        },
        card: {
            borderRadius: 1
        },
        carousel: {
            height: "100%",
            item: {
                padding: spacing.padding,
                color: colors.black,
                background: colors.white
            },
            arrows: {
                size: 1.5,
                color: colors.black,
                strokeColor: colors.white,
                filter: "drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5))",
                leftIcon: "arrowLeft",
                rightIcon: "arrowRight"
            },
            dots: {
                size: 0.5,
                activeBackground: colors.primary,
                background: colors.white,
                radius: 2,
                margin: 0.2
            }
        },
        chart: { budgeting: [colors.primary, colors.success, colors.warning] },
        checkbox: {
            color: colors.white,
            background: colors.secondary,
            disabled: colors.gray300,
            stroke: colors.black,
            radius: 3,
            size: 15
        },
        checkboxButton: {
            activeColor: colors.primary,
            defaultColor: colors.secondary
        },
        closeButton: {
            margin: spacing.margin,
            color: colors.black
        },
        column: {
            columns: 12,
            padding: spacing.padding,
            breakpoints: {
                small: 0,
                medium: 350,
                large: 700,
                xlarge: 1050
            }
        },
        circularProgress: {
            trailColor: colors.gray300,
            strokeColor: colors.accent,
            trailWidth: 10,
            strokeWidth: 10,
            strokeLinecap: "butt",
            textColor: colors.black
        },
        dashboard: {
            panel: {
                bar: {
                    iconSize: "1.2",
                    iconColor: colors.black,
                    background: colors.lightGray,
                    padding: "0.5",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    titleColor: colors.black,
                    textTransform: "uppercase"
                },
                content: {
                    background: colors.lightGray
                },
                wrapper: {
                    radius: 5,
                    boxShadow: "rgba(0, 18, 27, 0.2) 0px 2px 6px"
                },
                resize: {}
            }
        },
        dropzone: {
            default: "primary",
            radius: 0,
            margin: spacing.margin,
            padding: spacing.padding,
            border: "1px dashed",
            types: {
                default: {
                    background: colors.lightGray,
                    color: colors.secondary
                },
                dark: {
                    background: colors.secondary,
                    color: colors.white
                },
                accent: {
                    background: colors.primary,
                    color: colors.secondary
                }
            }
        },
        dropdown: {
            minWidth: "20",
            background: colors.lightGray
        },
        darkColor: colors.black,
        filters: {
            panel: {
                backgroundColor: colors.gray400
            },
            row: {
                backgroundColor: colors.gray200
            }
        },
        footer: {
            color: colors.white,
            background: colors.black,
            padding: spacing.padding,
            margin: spacing.margin
        },
        header: {
            color: colors.black,
            background: colors.white
        },
        helpText: {
            fontSize: "0.7rem",
            color: colors.gray600,
            fontStyle: "italic"
        },
        hero: {
            height: "500"
        },
        icon: {
            colors: colors,
            strokeWidth: 2
        },
        image: {
            containerBackground: colors.black
        },
        input: {
            sizes: sizes,
            borderColor: colors.gray500,
            radius: "3px",
            background: colors.white,
            disabledBackground: colors.gray200,
            color: colors.black,
            focusColor: colors.primary,
            iconColor: colors.primary,
            iconBackground: colors.lightGray,
            iconScale: 0.4,
            error: {
                color: colors.alert,
                borderColor: colors.alert
            }
        },
        lightColor: colors.white,
        link: {
            color: colors.black,
            colors: colors,
            fontWeight: "bold",
            textDecoration: "none"
        },
        loaders: {
            contentBlockLoader: {
                opacity: 0.2,
                gradientAngle: "-45deg",
                foreground: colors.gray800,
                background: colors.gray100,
                borderRadius: "3px"
            }
        },
        logo: {
            src: logo,
            height: "30px"
        },
        menu: {
            color: colors.secondary,
            background: colors.white,
            hoverBackground: colors.lightGray,
            borderBottom: "1px solid " + colors.lightGray,
            padding: 0.8,
            verticalPadding: 0.8,
            horizontalPadding: 1.5
        },
        modal: {
            padding: spacing.padding,
            margin: spacing.margin,
            radius: spacing.radius,
            background: colors.white,
            color: colors.secondary
        },
        navigation: {
            showMobileMenuBelow: breakpoints.medium,
            mobileNav: {
                buttonColor: colors.black,
                buttonFontSize: "1.4rem"
            },
            background: colors.neutral,
            //boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.14)",
            subNavigation: {
                background: colors.gray100
            },
            navLink: {
                margin: 0.6,
                color: colors.secondary,
                activeColor: colors.primary,
                activeTextDecoration: "underline",
                padding: 0.6,
                textTransform: "uppercase",
                fontWeight: "normal",
                fontFamily: font.fontMedium,
                fontSize: 16
                //activeFontWeight: "bold"
            },
            subNavLink: {
                fontFamily: font.fontBody,
                fontSize: 13,
                color: colors.accent,
                activeColor: colors.accent,
                activeTextDecoration: "underline",
                textTransform: "uppercase",
                fontWeight: "normal"
            }
        },
        pageTitle: {
            background: colors.white,
            fontSize: "1.5rem",
            fontFamily: font.fontHeading
        },
        pagination: {
            border: "1px solid " + colors.gray600,
            color: colors.black,
            background: colors.gray300,
            activeColor: colors.white,
            activeBackground: colors.secondary,
            activeFontWeight: "bold",
            arrowSize: "1.4rem",
            arrowColor: colors.gray600,
            hoverColor: colors.black,
            hoverBackground: colors.gray600,
            height: "2rem"
        },
        panel: {
            default: "white",
            headingBackground: colors.gray800,
            headingColor: colors.white,
            radius: 0,
            boxShadow: "none",
            // boxShadow: `${colors.gray200} 0px 2px 6px`,
            overlay: colors.black,
            background: colors.white,
            color: colors.black
        },
        planner: planner,
        progress: {
            borderColor: colors.lightGray,
            radius: spacing.radius,
            background: colors.white,
            barColor: colors.primary,
            textColor: colors.primary,
            textColorAlt: colors.white
        },
        search: {
            background: colors.white,
            boxShadow: "rgba(0, 18, 27, 0.2) 0px 2px 6px",
            boxShadowHover: "rgba(0, 18, 27, 0.2) 0px 2px 15px",
            // searchIcon: faChevronRight,
            // showCircle: true,
            color: colors.body,
            hoverColor: colors.accent,
            icons: {
                searchIcon: "chevron-right",
                color: "inherit",
                size: "20px",
                showCircle: true
            },
            image: {
                background: colors.gray900,
                height: "200px",
                objectFit: "contain",
                padding: "0.3rem"
            },
            summary: {
                color: colors.gray500
            },
            badge: {
                show: true,
                textTransform: "uppercase",
                badgeType: {
                    default: {
                        background: colors.gray800,
                        color: colors.white,
                        display: "none"
                    }
                }
            }
        },
        select: {
            background: colors.gray100,
            color: colors.black,
            highlighted: colors.highlight,
            hover: colors.gray200
        },
        steps: {
            barHeight: 20,
            spacing: -3,
            circleDiameter: 40,
            progressColor: colors.primary,
            backgroundColor: colors.white,
            borderColor: colors.secondary,
            borderRadius: 100,
            labelColor: "inherit",
            margin: spacing.margin,
            border: 1,
            padding: 4
        },
        tabordion: {
            background: colors.white,
            padding: spacing.padding,
            margin: spacing.margin,
            borderThickness: "1px",
            borderColor: colors.white,
            fontWeight: "bold",
            tabStyle: "tabs",
            default: {
                color: colors.black,
                background: colors.gray300,
                hover: colors.gray400
            },
            active: {
                color: colors.secondary,
                background: colors.gray100,
                borderColor: colors.gray300
            },
            content: {
                color: colors.black,
                background: colors.white
            },
            dividers: {
                height: 2.5,
                padding: 0.7,
                fontSize: 0.4,
                fontWeight: "bold",
                color: colors.white,
                background: colors.primary,
                border: "1px solid " + colors.white,
                radius: 2
            },
            twisty: {
                size: 2,
                color: colors.white,
                background: "transparent",
                nonActiveIcon: "arrowDown",
                activeIcon: "arrowUp"
            },
            tabs: {
                textAlign: "center"
            },
            accordion: {
                textAlign: "left"
            }
        },
        table: {
            margin: spacing.margin,
            padding: "0.5",
            types: {
                default: {
                    background: colors.white,
                    backgroundAlt: colors.lightGray,
                    rowHover: "#545963",
                    rowHighlight: colors.highlight,
                    thBackground: colors.secondary,
                    thColor: colors.white,
                    tdLineColor: colors.mediumGray
                },
                subtle: {
                    background: colors.white,
                    backgroundAlt: colors.lightGray,
                    rowHover: "#545963",
                    rowHighlight: colors.highlight,
                    thBackground: colors.gray300,
                    thColor: colors.black,
                    tdLineColor: colors.mediumGray
                }
            }
        },
        toast: {
            width: "400px",
            verticalPosition: "bottom",
            horizontalPosition: "right",
            background: colors.white,
            border: "1px solid " + colors.gray400,
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.14)",
            iconColor: colors.white,
            types: {
                alert: colors.alert,
                warning: colors.warning,
                success: colors.success,
                info: colors.info
            }
        },
        tooltip: {
            color: colors.white,
            background: colors.secondary,
            opacity: 0.95,
            padding: spacing.padding,
            margin: spacing.margin,
            radius: "3px",
            display: "none"
        },
        typography: {
            margin: spacing.margin,
            sizes: {
                // pageTitle: "1.5rem"
            }
        },
        userInfo: {
            spacingX: 0.8,
            spacingY: 0.2,
            padding: spacing.padding,
            backgroundColor: colors.white,
            height: 8,
            icon: {
                size: 1,
                color: colors.secondary
            },
            notification: {
                size: 0.7,
                top: -0.5,
                right: -0.5,
                minWidth: 1.2,
                color: colors.white,
                backgroundColor: colors.primary
            },
            title: {
                size: 1,
                color: colors.secondary
            },
            text: {
                size: 0.8,
                color: colors.primary
            }
        }
    };
    return merge(defaultTheme, siteTheme);
};

export default generateTheme();
