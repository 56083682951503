import React from "react";
//@ts-ignore cortex
import { useIntl, FormatNumberOptions } from "react-intl";

import {
    ContentBlockLoader
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

export const FormattedNumberWithLoader = ({
    value,
    loaded = true,
    options
}: {
    value: number;
    loaded?: boolean;
    options?: FormatNumberOptions;
}) => {
    const intl = useIntl();

    let amendedOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        ...options
    };

    return (
        <React.Fragment>
            <ContentBlockLoader loaded={loaded}>
                {intl.formatNumber(value, amendedOptions)}
            </ContentBlockLoader>
        </React.Fragment>
    );

    // return (
    //     <React.Fragment>
    //         <ContentBlockLoader loaded={!loaded} />
    //         {loaded && intl.formatNumber(value, amendedOptions)}
    //     </React.Fragment>
    // );
};
