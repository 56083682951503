import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { Collapse } from "react-collapse";
import FilterSummary from "./FilterSummary";
import StandardFilters from "./StandardFilters";
import ApplyFiltersRow from "./ApplyFiltersRow";
const FilterPanelWrapper = styled.div`
    margin-bottom: ${({ expanded }) => (expanded ? 1 : 0)}rem;
    background-color: ${({ theme }) => theme.filters.panel.backgroundColor};
`;

//Set filters and selected status at this level, then we can handle all state
//changes, until required to pass back up the tree.  This will allow us to
//choose whether to pass back up automatically (pinpoint style) or only on
//button press
let FilterPanel = ({
    children,
    permanentSummary,
    expanded,
    filters,
    selectedFilters,
    onUpdate,
    onApply,
    onClear,
    showApply,
    showSummary = true,
    resultCount,
    searchTerm,
    searchClear
}) => {
    // console.log("showSummary", showSummary);
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        selectedFilters
    );

    // console.log("FilterPanel", state, selectedFilters);
    useEffect(() => {
        // console.log("selectedFilters changed", selectedFilters);
        setState({ ...selectedFilters });
    }, [selectedFilters]);

    /**
     * Update the selected filters based upon the input passed in
     *
     * @param {object} input object containing a name and value pair
     */
    const updateFilters = ({ name, value }) => {
        // console.log(name, value);
        setState({ [name]: value });
        const tempState = { ...state, [name]: value };
        //Update the local selected filter state and pass the updated state
        //to the onUpdate callback
        onUpdate(tempState);
    };

    /**
     * Resets a whole section of the filters
     * @param {string} filterKey
     * @param {boolean} callApply
     */
    const resetFilterGroup = (
        filterKey,
        callUpdate = true,
        callApply = true
    ) => {
        // const filterToClear = filters[filterKey];
        const filterToClear = filters.filter(
            filter => filter.alias === filterKey
        )[0];

        const resetValue =
            filterToClear && filterToClear.defaultValue
                ? filterToClear.defaultValue
                : null;

        //As state is updated async we're also creating a temp state to
        //pass to the callback functions
        setState({ [filterKey]: resetValue });
        const tempState = { ...state, [filterKey]: resetValue };
        // console.log("tempState", tempState);
        if (callUpdate) {
            onUpdate(tempState);
        }
        if (callApply) {
            onApply(tempState);
        }
        return resetValue;
    };

    const clearFilters = () => {
        //Update the local selected filter state to be empty
        // and build up the temp state by calling resetFilterGroup
        // on each of the selectedFilters
        const tempState = Object.keys(state).reduce(
            (tempState, selectedFilter) => {
                // console.log(selectedFilter);
                const resetValue = resetFilterGroup(
                    selectedFilter,
                    false,
                    false
                );
                return { ...tempState, [selectedFilter]: resetValue };
            },
            {}
        );
        // console.log(tempState);
        setState({ ...state, ...tempState });
        //update and apply it
        onUpdate(tempState);
        onClear(tempState);
    };
    const applyFilters = () => {
        //Pass the current selected filter state the the onApply callback
        onApply(state);
    };
    // console.log(state);
    // console.log(searchTerm);
    return (
        <FilterPanelWrapper expanded={expanded}>
            <Collapse
                isOpened={expanded}
                // initialStyle={{ backgroundColor: "#d8d8d8" }}
            >
                {children ? (
                    children({
                        updateFilters,
                        clearFilters,
                        state,
                        filters
                    })
                ) : (
                    <StandardFilters
                        onClear={clearFilters}
                        onUpdate={updateFilters}
                        filters={filters}
                        selectedFilters={state}
                    ></StandardFilters>
                )}
                {showApply && (
                    <ApplyFiltersRow
                        onApply={applyFilters}
                        onClear={clearFilters}
                        resultCount={resultCount}
                        filters={filters}
                        selectedFilters={state}
                    ></ApplyFiltersRow>
                )}
            </Collapse>
            <Collapse isOpened={showSummary && (permanentSummary || !expanded)}>
                <FilterSummary
                    onUpdate={resetFilterGroup}
                    onClear={clearFilters}
                    filters={filters}
                    selectedFilters={state}
                    searchTerm={searchTerm}
                    searchClear={searchClear}
                />
            </Collapse>
        </FilterPanelWrapper>
    );
};

/*
<FilterPanel expanded={true} filters={filters}>
    {({filters}, selectedFilters)=>{
        return <StandardFilters filters={filters} selectedFilters={selectedFilters} />
        <FilterSummary filters={filters} selectedFilters={selectedFilters} />
    }}
</FilterPanel>
*/

/*
<FilterPanel
    expanded={true}
    filters={filters}
    selectedFilters={selectedFilters}
    onUpdate={updateFilters}
    onClear={clearFilters}
/>
*/

/*
<FilterPanel
    expanded={true}
    filters={filters}
    selectedFilters={selectedFilters}
>
        <CustomFilterPanel
            onUpdate={updateFilters}
            onClear={clearFilters}
        />
</FilterPanel>

*/

FilterPanel = withTheme(FilterPanel);

FilterPanel.displayName = "FilterPanel";

const filterShape = PropTypes.shape({
    alias: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
        "MultiCheckBox",
        "MultiSelect",
        "Select",
        "RadioGroup",
        "Range"
    ]).isRequired,
    comparison: PropTypes.oneOf(["===", "!==", "<", "<=", ">", ">="]),
    defaultValue: PropTypes.any,
    options: PropTypes.arrayOf({
        value: PropTypes.any.isRequired,
        text: PropTypes.string.isRequired
    })
});
FilterPanel.propTypes = {
    /** Filters: Need to be of a certain shape.*/
    filters: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape(filterShape)),
        PropTypes.array
    ]),
    // filters: PropTypes.objectOf(PropTypes.shape(filterShape)).isRequired,
    selectedFilters: PropTypes.object,
    children: PropTypes.func,
    permanentSummary: PropTypes.bool,
    expanded: PropTypes.bool,
    onUpdate: PropTypes.func,
    onApply: PropTypes.func,
    showApply: PropTypes.bool,
    /** Used to display a result count if the filters would be applied */
    resultCount: PropTypes.oneOfType([PropTypes.number])
};

FilterPanel.defaultProps = {
    selectedFilters: {},
    permanentSummary: false,
    expanded: false,
    onApply: () => {},
    autoApply: false,
    showApply: true
};

export default FilterPanel;
