import React from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";

const ResultSummary = ({ from, to, total }) => {
    if (
        typeof from === "undefined" ||
        typeof to === "undefined" ||
        typeof total === "undefined"
    ) {
        return null;
    }
    return (
        <React.Fragment>
            <div style={{ textAlign: "right", padding: "0 0.6rem" }}>
                {trans("Currently showing {from}-{to} of {total}", {
                    from,
                    to,
                    total
                })}
            </div>
        </React.Fragment>
    );
};

export default ResultSummary;
