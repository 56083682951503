import PropTypes from "prop-types";
import styled from "styled-components";
import React from "react";

const LoadingWrapper = styled.div`
    width: ${({ size }) => size};
    height: ${({ size }) => size};
`;

const LoadingIcon = styled.svg`
    margin: auto;
    max-width: 100px;
    animation: loadingIcon 1s infinite linear;
    transform-origin: 50% 50%;
    [stroke-dasharray] {
        stroke: black;
        stroke-linecap: round;
    }

    @keyframes loadingIcon {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

const LoadingIconSmall = ({ color, ...rest }) => {
    return (
        <LoadingWrapper className="loadingBox" {...rest}>
            <LoadingIcon
                className="loadingIcon"
                height="100%"
                width="100%"
                viewBox="0 0 50 50"
            >
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    stroke="lightgray"
                    strokeWidth="5"
                    fill="transparent"
                />
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    strokeWidth="5"
                    fill="transparent"
                    strokeDasharray="25 100"
                />
            </LoadingIcon>
        </LoadingWrapper>
    );
};

LoadingIconSmall.propTypes = {
    color: PropTypes.string,
    size: PropTypes.string
};
LoadingIconSmall.defaultProps = {
    color: "rgba(235,235,235,0.2)",
    size: "30px"
};

export default LoadingIconSmall;
